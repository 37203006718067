import './App.css';
import ReactDOM from 'react-dom';
import { Color } from './flatColors';
import { useDispatch, useSelector, useStore } from "react-redux";
import React, {useState, useEffect} from 'react';
//import { styled } from '@mui/material/styles';
//import styled from '@emotion/styled'
import styled from "@emotion/styled/macro";
import { updateCalculatedLayout, searchProducts, resetState, logOutMagento, getCategoryTree, setCartTotal, getCustomerInfo } from "./redux/actions";
import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline, A, Img } from './Components/TypographyComponents'; 
import { Button, Divider, IconButton, Menu, MenuItem, MenuList, TextField, Typography } from '@mui/material';
import { Close, Face, Login, Logout, Phone, PinDrop, Search, ShoppingBag, ShoppingBagOutlined } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import MenuIcon from '@mui/icons-material/Menu';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import { CallBackButton, SearchIcon } from './View16/Components';
import { Link, useNavigate } from "react-router-dom";
import { getCartTotalProducts } from './ApiHelper';
import _ from 'lodash';
import uuid from 'react-uuid';

//sm, md, lg, xl
const Breakpoints = {
  small: { min: 0, max: 600 },
  medium: { min: 601, max: 900 },
  large: { min: 901, max: 1200 },
  huge: { min: 1201, max: 1410 }
};

const ScreenType = {
  gird4: 0,
  grid8: 1,
  grid12: 2,
  grid16: 3,
  unknown: 4
} 

export const calc = function (maxWidth, margin) {
  let lt = {};
  let width = Breakpoints.small.min;

  if (maxWidth <= Breakpoints.huge.max) {
    width = maxWidth;
  } else {
    width = Breakpoints.huge.max;
  }

  lt.margin = margin;
  lt.col = 60;
  lt.gutter = margin;
  let type = getScreenType(width);
  if (type === ScreenType.gird4) {
    lt.max = 2;
    lt.containerWidth = width;
  } else if (type === ScreenType.grid8) {
    lt.max = 4;
    lt.containerWidth = width;
  } else if (type === ScreenType.grid12) {
    lt.max = 6;
    lt.containerWidth = width;
  } else if (type === ScreenType.grid16) {
    lt.max = 8;
    lt.containerWidth = width;
  } else {
    return lt;
  }

  let half = lt.containerWidth / 2;
  let total = (lt.col + lt.gutter + lt.margin) * lt.max;
  let adj = (total - half) / lt.max;
  lt.col -= adj;
  lt.max *= 2;

  lt.containerWidth = (lt.col + lt.gutter + lt.margin) * lt.max;
  lt.innerWidth = lt.containerWidth - lt.margin * 2;
  lt.gutter *= 2;
  lt.bunch1 = lt.col;
  lt.bunch2 = lt.bunch1 + (lt.col + lt.gutter);
  lt.bunch3 = lt.bunch2 + (lt.col + lt.gutter);
  lt.bunch4 = lt.bunch3 + (lt.col + lt.gutter);
  lt.bunch5 = lt.bunch4 + (lt.col + lt.gutter);
  lt.bunch6 = lt.bunch5 + (lt.col + lt.gutter);
  lt.bunch7 = lt.bunch6 + (lt.col + lt.gutter);
  lt.bunch8 = lt.bunch7 + (lt.col + lt.gutter);
  lt.bunch9 = lt.bunch8 + (lt.col + lt.gutter);
  lt.bunch10 = lt.bunch9 + (lt.col + lt.gutter);
  lt.bunch11 = lt.bunch10 + (lt.col + lt.gutter);
  lt.bunch12 = lt.bunch11 + (lt.col + lt.gutter);
  lt.bunch13 = lt.bunch12 + (lt.col + lt.gutter);
  lt.bunch14 = lt.bunch13 + (lt.col + lt.gutter);
  lt.bunch15 = lt.bunch14 + (lt.col + lt.gutter);
  lt.bunch16 = lt.bunch15 + (lt.col + lt.gutter);

  lt.space0 = lt.margin;
  lt.space1 = lt.space0 + (lt.col + lt.gutter);
  lt.space2 = lt.space1 + (lt.col + lt.gutter);
  lt.space3 = lt.space2 + (lt.col + lt.gutter);
  lt.space4 = lt.space3 + (lt.col + lt.gutter);
  lt.space5 = lt.space4 + (lt.col + lt.gutter);
  lt.space6 = lt.space5 + (lt.col + lt.gutter);
  lt.space7 = lt.space6 + (lt.col + lt.gutter);
  lt.space8 = lt.space7 + (lt.col + lt.gutter);
  lt.space9 = lt.space8 + (lt.col + lt.gutter);
  lt.space10 = lt.space9 + (lt.col + lt.gutter);
  lt.space11 = lt.space10 + (lt.col + lt.gutter);
  lt.space12 = lt.space11 + (lt.col + lt.gutter);
  lt.space13 = lt.space12 + (lt.col + lt.gutter);
  lt.space14 = lt.space13 + (lt.col + lt.gutter);
  lt.space15 = lt.space14 + (lt.col + lt.gutter);

  return lt;
}

function getScreenType(width) {
  if (width >= Breakpoints.small.min && width <= Breakpoints.small.max) {
    return ScreenType.gird4;
  } else if (width > Breakpoints.medium.min && width <= Breakpoints.medium.max) {
    return ScreenType.grid8;
  } else if (width > Breakpoints.medium.min && width <= Breakpoints.large.max) {
    return ScreenType.grid12;
  } else if (width > Breakpoints.huge.min && width <= Breakpoints.huge.max) {
    return ScreenType.grid16;
  } else {
    return ScreenType.unknown;
  }
}

export const Axis = {
  main: {
    start: "flex-start",
    end: "flex-end",
    center: "center",
    between: "space-between",
    around: "space-around",
    evenly: "space-evenly",
    initial: "initial",
    inherit: "inherit",
  },
  cross: {
    stretch: "stretch",
    start: "flex-start",
    end: "flex-end",
    center: "center",
    baseline: "baseline",
    initial: "initial",
    inherit: "inherit",
  },
  self: {
    auto: "auto",
    initial: "initial",
    inherit: "inherit",
    stretch: "stretch",
    start: "flex-start",
    end: "flex-end",
    center: "center",
    baseline: "baseline"
  }
}

export function Row(props) {
  
  const filterProps = ["debug", "center", "top", "left", "right", "bottom", "width", "height", "color", "main", "cross", "self", "sx"];

  let style = {
    display: 'flex',
    flexDirection: 'row',
  };
  if (props.width) {
    style.width = props.width;
  }
  if (props.height) {
    style.height = props.height;
  }
  if (props.color) {
    style.backgroundColor = props.color;
  }
  if (props.center) {
    style.justifyContent = 'center';
    style.alignItems = 'center';
  }
  if (props.main) {
    style.justifyContent = props.main;
  }
  if (props.cross) {
    style.alignItems = props.cross;
  }
  if (props.self) {
    style.alignSelf = props.self;
  }
  if (props.top) {
    style.marginTop = props.top;
  }
  if (props.right) {
    style.marginRight = props.right;
  }
  if (props.left) {
    style.marginLeft = props.left;
  }
  if (props.bottom) {
    style.marginBottom = props.bottom;
  }
  if (props.debug) {
    style.borderStyle = 'dashed';
    style.borderColor = 'black';
    style.borderWidth = 2;
    style.boxSizing = 'border-box';
  };
  style = {...style, ...props.sx};

  let copyProps = {...props};
  for (let item of filterProps) {
    if (copyProps.hasOwnProperty(item)) {
      delete copyProps[item];
    }
  }
  
  return (
    <div style={style} {...copyProps}>
      {props.children}
    </div>
  );
}


export function Column(props) {
  
  const filterProps = ["debug", "center", "top", "left", "right", "bottom", "width", "height", "color", "main", "cross", "self", "sx"];

  let style = {
    display: 'flex',
    flexDirection: 'column',
  };
  if (props.width) {
    style.width = props.width;
  }
  if (props.height) {
    style.height = props.height;
  }
  if (props.color) {
    style.backgroundColor = props.color;
  }
  if (props.center) {
    style.justifyContent = 'center';
    style.alignItems = 'center';
  }
  if (props.main) {
    style.justifyContent = props.main;
  }
  if (props.cross) {
    style.alignItems = props.cross;
  }
  if (props.self) {
    style.alignSelf = props.self;
  }
  if (props.top) {
    style.marginTop = props.top;
  }
  if (props.right) {
    style.marginRight = props.right;
  }
  if (props.left) {
    style.marginLeft = props.left;
  }
  if (props.bottom) {
    style.marginBottom = props.bottom;
  }
  if (props.debug) {
    style.borderStyle = 'dashed';
    style.borderColor = 'black';
    style.borderWidth = 2;
    style.boxSizing = 'border-box';
  };
  style = {...style, ...props.sx};

  let copyProps = {...props};
  //console.log(copyProps);
  for (let item of filterProps) {
    if (copyProps.hasOwnProperty(item)) {
      delete copyProps[item];
    } 
  }
  
  return (
    <div style={style} {...copyProps}>
      {props.children}
    </div>
  );
}

export function Stack(props) {
  
  const filterProps = ["debug", "top", "left", "right", "bottom", "width", "height", "color", "sx"];

  /*let style = {
    position: 'relative',
    width: this.props.width,
    height: this.props.height,
    top: this.props.top,
    left: this.props.left,
    right: this.props.right,
    bottom: this.props.bottom,
    backgroundColor: this.props.color,
    borderStyle: this.props.debug ? 'dashed' : 'none',
    borderColor: this.props.debug ? 'black' : 'none',
    borderWidth: this.props.debug ? 2 : 0,
    boxSizing: this.props.debug ? 'border-box' : 'none',
  }*/

  let style = {
    position: 'relative',
  };
  if (props.width) {
    style.width = props.width;
  }
  if (props.height) {
    style.height = props.height;
  }
  if (props.color) {
    style.backgroundColor = props.color;
  }
  if (props.top) {
    style.marginTop = props.top;
  }
  if (props.right) {
    style.marginRight = props.right;
  }
  if (props.left) {
    style.marginLeft = props.left;
  }
  if (props.bottom) {
    style.marginBottom = props.bottom;
  }
  if (props.debug) {
    style.borderStyle = 'dashed';
    style.borderColor = 'black';
    style.borderWidth = 2;
    style.boxSizing = 'border-box';
  };
  style = {...style, ...props.sx};

  let copyProps = {...props};
  for (let item of filterProps) {
    if (copyProps.hasOwnProperty(item)) {
      delete copyProps[item];
    }
  }
  
  return (
    <div style={style} {...copyProps}>
      {props.children}
    </div>
  );
}


export function StackItem(props) {
  
  const filterProps = ["debug", "top", "left", "right", "bottom", "width", "height", "color", "z", "sx"];

  /*
  let style = {
      position: 'absolute',
      width: this.props.width,
      height: this.props.height,
      top: this.props.top,
      left: this.props.left,
      right: this.props.right,
      bottom: this.props.bottom,
      backgroundColor: this.props.color,
      zIndex: this.props.zindex,
      borderStyle: this.props.debug ? 'dashed' : 'none',
      borderColor: this.props.debug ? 'black' : 'none',
      borderWidth: this.props.debug ? 2 : 0,
      boxSizing: this.props.debug ? 'border-box' : 'none',
    }
  */

  let style = {
    position: 'absolute',
  };
  if (props.z) {
    style.zIndex = props.z;
  }
  if (props.top) {
    style.top = props.top;
  }
  if (props.left) {
    style.left = props.left;
  }
  if (props.right) {
    style.right = props.right;
  }
  if (props.bottom) {
    style.bottom = props.bottom;
  }
  if (props.width) {
    style.width = props.width;
  } else {
    //style.width ='auto';
  }
  if (props.height) {
    style.height = props.height;
  } else {
    //style.height = "auto";
  }
  if (props.color) {
    style.backgroundColor = props.color;
  }
  if (props.debug) {
    style.borderStyle = 'dashed';
    style.borderColor = 'black';
    style.borderWidth = 2;
    style.boxSizing = 'border-box';
  };
  style = {...style, ...props.sx};

  let copyProps = {...props};
  for (let item of filterProps) {
    if (copyProps.hasOwnProperty(item)) {
      delete copyProps[item];
    }
  }
  
  return (
    <div style={style} {...copyProps}>
      {props.children}
    </div>
  );
}




export function ContentContainer(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;

  

  useEffect(()=>{
    const update = ()=> {
      dispatch(updateCalculatedLayout(calc(window.innerWidth, 4)));
    }
    //console.log(' ContentContainer mounted');
    window.addEventListener("resize", update);

    return () => {
      window.addEventListener("resize", update);
      //console.log("ContentContainer unmounted");
    };
  },[lt.containerWidth, dispatch]);

  return(
    <div style={{display:'flex', flexDirection:'column', minHeight:'100vh'}}>
      {props.debug &&
        <Column center><Dbg></Dbg></Column>
      }
      {props.children}
    </div>
  );
}

export const Head = styled('div')`
  width:100%;
`;

export const Content = styled('div')`
  width:100%;
`;

export const Filler = styled('div')`
  flex:1;
  overflow:auto;
`;

/*export const Footer = styled('div')`
  display:flex;
  flex-direction:column;
  width:100%;
`;*/

const Telephone = styled(Button)`
  color:white;
  height:25px;
  font-size:15px;
  letter-spacing:2.1px;
  font-weight:600;
  padding:0;
  &:hover {
    color:#6abdd2;
  }
`;

const FooterSubTitle = styled(Button)`
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  color: #fff;
  font-size:16px;
  padding-top:0px;
  padding-bottom:0px;
  padding-left:0px;
  padding-right:0px;
  width:fit-content;
  justify-content:start;
  text-align:left;
  line-height:1.3;
`;

const FooterSubTitleText = styled('span')`
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  color: #fff;
  font-size:16px;
  line-height:1.3;
  background-color:#26313e;
`;

const FooterListItem = styled(Button)`
  font-size: 14px;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 300;
  padding-top:3px;
  padding-bottom:3px;
  padding-left:0px;
  padding-right:0px;
  justify-content:start;
  text-align:left;
  width:fit-content;
  line-height:1.3;
  &:hover {
    color:#6abdd2;
  }
`;

export function Footer() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const categoryTree = stateObj.categoryTree;
  const navigate = useNavigate();
  let tree = {name: "", uid:"", children_count:0, children:[]};

  useEffect(()=>{
    if (categoryTree.length === 0) { 
      dispatch(getCategoryTree());
    }
  }, [
    JSON.stringify(categoryTree),
  ]);

  //console.log(categoryTree);

  if (Object.keys(categoryTree).length > 0) {
    tree = categoryTree[0].children[0];
  }

  const productHandler = (event)=>{
    const obj = JSON.parse(event.target.dataset.data);
    //console.log(obj.uid);
    navigate(`/category/?uid=${obj.uid}`);
  }

  return(
    <footer>
      <Typography variant='div' sx={{color:'white'}}>
        <Column width="100%" sx={{backgroundColor:"#26313e", paddingTop:60, paddingBottom:20}} center>
          <Column width={lt.innerWidth} >
            <div style={{display: lt.max === 16 || lt.max === 12 ? 'block' : 'none'}}>
              <Column left={20} right={20} >
                <Row>
                  <Row right={40}>
                    <Column>
                      <A href="/" style={{width:120, textDecoration:"none"}}><Img src="/images/logo-wh.png" /></A>
                    </Column>
                    <Column left={50} cross={Axis.cross.end} >
                      <Row>
                        <Row right={15}><CallBackButton sx={{color: "#26313e"}}>Call Back</CallBackButton></Row>
                        <Telephone startIcon={<Phone sx={{width:12}} />}>1 (855) 997-9701</Telephone>
                      </Row>
                      <Row top={3}><Telephone >tel (212) 997-4343</Telephone></Row>
                      <Row top={3}><Telephone >fax (212) 997-9701</Telephone></Row>
                      <Row cross={Axis.cross.start} top={20} sx={{textAlign:"right"}}>
                        <Row top={5} right={2}><PinDrop sx={{height:17}} /></Row>
                        <Subtitle2 color="white" sx={{fontSize:15, fontWeight:600, lineHeight:2 }}>JR Castings & Findings, Inc.<br/>43 West 47th Street<br/>New York, NY 10036</Subtitle2>
                      </Row>
                    </Column>
                  </Row>
                  <Row sx={{flex:1}} main={Axis.main.between}>
                    <Column right={10} >
                      <FooterSubTitle>Company</FooterSubTitle>
                      <Column top={10} >
                        <Link to="/category" style={{textDecoration:'none'}}><Row top={3} bottom={3}><FooterListItem>Our Catalogs</FooterListItem></Row></Link>
                        <Link to="/about" style={{textDecoration:'none'}}><Row top={3} bottom={3}><FooterListItem>Company policies</FooterListItem></Row></Link>
                        <Link to="/faq" style={{textDecoration:'none'}}><Row top={3} bottom={3}><FooterListItem>FAQ</FooterListItem></Row></Link>
                      </Column>
                    </Column>
                    <Column right={10} >
                      <FooterSubTitle>OUR SERVICES</FooterSubTitle>
                      <Column top={10} bottom={13}>
                        <Link to="/spec3dwax" style={{textDecoration:'none'}}><Row top={3} bottom={3}><FooterListItem>3D Wax Print</FooterListItem></Row></Link>
                        <Link to="/specdesign" style={{textDecoration:'none'}}><Row top={3} bottom={3}><FooterListItem>Make your own design</FooterListItem></Row></Link>
                        <Link to="/stone_calc" style={{textDecoration:'none'}}><Row top={3} bottom={3}><FooterListItem>Stone Calculator</FooterListItem></Row></Link>
                      </Column>
                      <FooterSubTitle>PERSONAL ACCOUNT</FooterSubTitle>
                      <Column top={10}>
                        <Link to="/login" style={{textDecoration:'none'}}><Row top={3} bottom={3}><FooterListItem>Sign In</FooterListItem></Row></Link>
                        <Link to="/login" style={{textDecoration:'none'}}><Row top={3} bottom={3}><FooterListItem>Password Recovery</FooterListItem></Row></Link>
                      </Column>
                    </Column>
                    <Column >
                      <FooterSubTitle>PRODUCTS</FooterSubTitle>
                      <Column top={10}>
                        { tree.children.map((v,i)=>{
                            return(
                              <Row key={v.uid} top={3} bottom={3} ><FooterListItem data-data={JSON.stringify(v)} onClick={productHandler}>{v.name}</FooterListItem></Row>
                            );
                          })
                        }
                      </Column>
                    </Column>
                  </Row>
                </Row>
                <Row top={20}>
                  <Subtitle1 sx={{color:'white', fontSize:12}}>Copyright © {new Date().getFullYear()} JR Castings & Findings, Inc. All rights reserved.</Subtitle1>
                </Row>
              </Column>
            </div>
            <div style={{display: lt.max === 8 ? 'block' : 'none'}}>
              <Column left={20} right={20} >
                <Row main={Axis.main.between} bottom={20}>
                  <Column >
                    <A href="/" style={{width:120, textDecoration:"none"}}><Img src="/images/logo-wh.png" /></A>
                  </Column>
                  <Column cross={Axis.cross.end}>
                    <Row>
                      <Row right={15}><CallBackButton sx={{color: "#26313e"}}>Call Back</CallBackButton></Row>
                      <Telephone startIcon={<Phone sx={{width:12}} />}>1 (855) 997-9701</Telephone>
                    </Row>
                    <Row top={3}><Telephone >tel (212) 997-4343</Telephone></Row>
                    <Row top={3}><Telephone >fax (212) 997-9701</Telephone></Row>
                    <Row cross={Axis.cross.start} sx={{textAlign:"right"}} top={20} >
                      <Row top={5} right={2}><PinDrop sx={{height:17}} /></Row>
                      <Subtitle2 color="white" sx={{fontSize:15, fontWeight:600, lineHeight:2 }}>JR Castings & Findings, Inc.<br/>43 West 47th Street<br/>New York, NY 10036</Subtitle2>
                    </Row>
                  </Column>
                </Row>
                <Divider sx={{backgroundColor:'#8c92ac'}}></Divider>
                <Row top={25} sx={{flex:1}} main={Axis.main.between}>
                  <Column>
                    <FooterSubTitle>Company</FooterSubTitle>
                    <Column top={10} >
                      <Row top={3} bottom={3}><FooterListItem>Our Catalogs</FooterListItem></Row>
                      <Row top={3} bottom={3}><FooterListItem>How to Order</FooterListItem></Row>
                      <Row top={3} bottom={3}><FooterListItem>Jewelry Dictionary</FooterListItem></Row>
                      <Row top={3} bottom={3}><FooterListItem>Credit Application Forms</FooterListItem></Row>
                      <Row top={3} bottom={3}><FooterListItem>Special Services</FooterListItem></Row>
                      <Row top={3} bottom={3}><FooterListItem>Fax Order Forms</FooterListItem></Row>
                      <Row top={3} bottom={3}><FooterListItem>Company policies</FooterListItem></Row>
                      <Row top={3} bottom={3}><FooterListItem>FAQ</FooterListItem></Row>
                    </Column>
                  </Column>
                  <Column>
                    <FooterSubTitle>OUR SERVICES</FooterSubTitle>
                    <Column top={10} bottom={13}>
                      <Row top={3} bottom={3}><FooterListItem>3D Wax Print</FooterListItem></Row>
                      <Row top={3} bottom={3}><FooterListItem>Make your own design</FooterListItem></Row>
                    </Column>
                    <FooterSubTitle>PERSONAL ACCOUNT</FooterSubTitle>
                    <Column top={10}>
                      <Row top={3} bottom={3}><FooterListItem>Sign In</FooterListItem></Row>
                      <Row top={3} bottom={3}><FooterListItem>Password Recovery</FooterListItem></Row>
                    </Column>
                  </Column>
                  <Column>
                    <FooterSubTitle>PRODUCTS</FooterSubTitle>
                    <Column top={10}>
                      { tree.children.map((v,i)=>{
                          return(
                            <Row key={v.uid} top={3} bottom={3} ><FooterListItem data-data={JSON.stringify(v)} onClick={productHandler}>{v.name}</FooterListItem></Row>
                          );
                        })
                      }
                    </Column>
                  </Column>
                </Row>
                <Row top={20}>
                  <Subtitle1 sx={{color:'white', fontSize:12}}>Copyright © {new Date().getFullYear()} JR Castings & Findings, Inc. All rights reserved.</Subtitle1>
                </Row>
              </Column>
            </div>
            <div style={{display: lt.max === 4 ? 'block' : 'none'}}>
              <Column left={10} right={10} >
                <Column bottom={25}>
                  <A href="/" style={{width:120, textDecoration:"none"}}><Img src="/images/logo-wh.png" /></A>
                </Column>
                <Column bottom={20}>
                  <Row top={3}><Telephone startIcon={<Phone sx={{width:12}} />}>1 (855) 997-9701</Telephone></Row>
                  <Row top={3}><Telephone >tel (212) 997-4343</Telephone></Row>
                  <Row top={3}><Telephone >fax (212) 997-9701</Telephone></Row>
                  <Row cross={Axis.cross.start} main={Axis.main.end} top={20} sx={{textAlign:"right"}} >
                    <Row top={5} right={2}><PinDrop sx={{height:17}} /></Row>
                    <Subtitle2 color="white" sx={{fontSize:15, fontWeight:600, lineHeight:2 }}>JR Castings & Findings, Inc.<br/>43 West 47th Street<br/>New York, NY 10036</Subtitle2>
                  </Row>
                </Column>
                <Divider sx={{backgroundColor:'#8c92ac'}}></Divider>
                <Column top={20}>
                  <Accordion square={false} sx={{backgroundColor:"#26313e", border:"none", boxShadow:"none"}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{color:"#eee"}} />}
                      aria-controls="panel1a-content"
                      sx={{paddingLeft:0}}
                    >
                      <FooterSubTitleText>Company</FooterSubTitleText>
                    </AccordionSummary>
                    <AccordionDetails sx={{paddingLeft:0, paddingRight:0, marginLeft:0}}>
                      <Column top={0} >
                        <Row top={3} bottom={3}><FooterListItem>Our Catalogs</FooterListItem></Row>
                        <Row top={3} bottom={3}><FooterListItem>How to Order</FooterListItem></Row>
                        <Row top={3} bottom={3}><FooterListItem>Jewelry Dictionary</FooterListItem></Row>
                        <Row top={3} bottom={3}><FooterListItem>Credit Application Forms</FooterListItem></Row>
                        <Row top={3} bottom={3}><FooterListItem>Special Services</FooterListItem></Row>
                        <Row top={3} bottom={3}><FooterListItem>Fax Order Forms</FooterListItem></Row>
                        <Row top={3} bottom={3}><FooterListItem>Company policies</FooterListItem></Row>
                        <Row top={3} bottom={3}><FooterListItem>FAQ</FooterListItem></Row>
                      </Column>
                    </AccordionDetails>
                  </Accordion>
                </Column>
                <Column top={0}>
                  <Accordion square={false} sx={{backgroundColor:"#26313e", border:"none", boxShadow:"none"}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{color:"#eee"}} />}
                      aria-controls="panel1a-content"
                      sx={{paddingLeft:0}}
                    >
                      <FooterSubTitleText>OUR SERVICES</FooterSubTitleText>
                    </AccordionSummary>
                    <AccordionDetails sx={{paddingLeft:0, paddingRight:0, marginLeft:0}}>
                      <Column top={0} >
                        <Row top={3} bottom={3}><FooterListItem>3D Wax Print</FooterListItem></Row>
                        <Row top={3} bottom={3}><FooterListItem>Make your own design</FooterListItem></Row>
                      </Column>
                    </AccordionDetails>
                  </Accordion>
                </Column>
                <Column top={0}>
                  <Accordion square={false} sx={{backgroundColor:"#26313e", border:"none", boxShadow:"none"}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{color:"#eee"}} />}
                      aria-controls="panel1a-content"
                      sx={{paddingLeft:0}}
                    >
                      <FooterSubTitleText>PERSONAL ACCOUNT</FooterSubTitleText>
                    </AccordionSummary>
                    <AccordionDetails sx={{paddingLeft:0, paddingRight:0, marginLeft:0}}>
                      <Column top={0} >
                        <Row top={3} bottom={3}><FooterListItem>Sign In</FooterListItem></Row>
                        <Row top={3} bottom={3}><FooterListItem>Password Recovery</FooterListItem></Row>
                      </Column>
                    </AccordionDetails>
                  </Accordion>
                </Column>
                <Column top={0}>
                  <Accordion square={false} sx={{backgroundColor:"#26313e", border:"none", boxShadow:"none"}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{color:"#eee"}} />}
                      aria-controls="panel1a-content"
                      sx={{paddingLeft:0}}
                    >
                      <FooterSubTitleText>PRODUCTS</FooterSubTitleText>
                    </AccordionSummary>
                    <AccordionDetails sx={{paddingLeft:0, paddingRight:0, marginLeft:0}}>
                      <Column top={0} >
                        { tree.children.map((v,i)=>{
                            return(
                              <Row key={v.uid} top={3} bottom={3} ><FooterListItem data-data={JSON.stringify(v)} onClick={productHandler}>{v.name}</FooterListItem></Row>
                            );
                          })
                        }
                      </Column>
                    </AccordionDetails>
                  </Accordion>
                </Column>
                <Row top={20}>
                  <Subtitle1 sx={{color:'white', fontSize:12}}>Copyright © {new Date().getFullYear()} JR Castings & Findings, Inc. All rights reserved.</Subtitle1>
                </Row>
              </Column>
            </div>
          </Column>
        </Column>
      </Typography>
    </footer>
  );
}

const HeaderTelephone = styled(Button)`
  color:#000;
  height:25px;
  font-size:13px;
  letter-spacing:1.18px;
  font-weight:600;
  padding:0;
  &:hover {
    color:#6abdd2;
  }
`;

const HeaderButton = styled(Button)`
  color:#000;
  height:30px;
  font-size: 13px;
  letter-spacing: .9px;
  text-transform: uppercase;
  padding:0;
  font-weight:300;
  &:hover {
    color:#6abdd2;
  }
`;

function AccountMainMenu() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const customerInfo = stateObj.customerInfo;
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const accountButtonHandler = () => {
    dispatch(logOutMagento());
  }

  const profileHandler = () => {
    navigate("/profile");
  }

  //console.log(customerInfo);

  useEffect(()=>{
    //if (_.isEmpty(customerInfo)) {
      dispatch(getCustomerInfo(customerToken));
    //}
  },[]);

  let customerName = "My Account";
  if (_.isEmpty(customerInfo) === false && customerInfo.hasOwnProperty("firstname")) {
    customerName = "Hi, "+ customerInfo.firstname;
  } 

  return (
    <>
      <div style={{display: lt.max >= 8 && lt.max <= 16 ? 'block': 'none'}}>
        <HeaderButton
          id="acc-button"
          aria-controls={open ? 'acc-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          startIcon={<Face></Face>}
        >
          { customerName }
        </HeaderButton>
        <Menu 
          id="acc-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'acc-button',
          }}
        >
          <MenuItem onClick={profileHandler}>Profile</MenuItem>
          <MenuItem onClick={accountButtonHandler}>Logout</MenuItem>
        </Menu>
      </div>
      <div style={{display: lt.max === 4 ? 'block': 'none'}}>
        <IconButton 
          id="acc-button"
          aria-controls={open ? 'acc-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <Face sx={{height:20, color:"#000"}} />
        </IconButton>
        <Menu 
          id="acc-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'acc-button',
          }}
        >
          <MenuItem onClick={profileHandler}>Profile</MenuItem>
          <MenuItem onClick={accountButtonHandler}>Logout</MenuItem>
        </Menu>
      </div>
    </>
  );
}


export function Header() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const cartTotal = stateObj.cartTotal;
  const searchedProducts = stateObj.searchedProducts;

  const [searchField, showShearchField] = useState(false);
  const [searchText, showShearchText] = useState("");

  const searchRef = React.useRef();
  const navigate = useNavigate();

  useEffect(()=>{
    if (customerToken) {
      getCartTotalProducts(customerToken, (total)=>{
        if (total !== cartTotal) {
          if (total > 0) {
            dispatch(setCartTotal(total));
          }
        }
      });
    }
    dispatch(resetState({searchedProducts:[]}));
  }, [cartTotal]);

  const searcShowhHandler = ()=>{
    showShearchField(!searchField);
  }

  const cartHandler = () => {
    navigate("/cart");
  }

  const searchCloseHandler = ()=>{
    dispatch(resetState({searchedProducts:[]}));
    showShearchField(false);
    showShearchText("");
    searchRef.current.value = "";
  }

  const searchItemsHandler = ()=>{
    dispatch(resetState({searchedProducts:[]}));
    navigate(`/search/?uuid=${uuid()}&text=${searchText}`);
  }

  const searchFieldChangeHandler = (event) => {
    let text = event.target.value.trim();
    showShearchText(text);
    if (text) {
      if (text.length > 2) {
        dispatch(searchProducts(event.target.value));
      }
    } else {
      console.log('reset');
      dispatch(resetState({searchedProducts:[]}));
    }
  }

  const searchFormSelectHandler = (event) => {
    let sku = event.currentTarget.dataset.sku;
    //console.log(sku);
    searchRef.current.value = "";
    dispatch(resetState({searchedProducts:[]}));
    showShearchField(false);
    navigate(`/product?uuid=${uuid()}&sku=${sku}`);
  }

  let height = 128;
  if (lt.max === 4) {
    height = 70;
  }

  let accountButton = null;
  if (lt.max > 8) {
    if (!customerToken) {
      accountButton = <Link to={"/login"} style={{textDecoration: "none"}}>
        <HeaderButton startIcon={<Login sx={{height:20}} />}>Log in</HeaderButton>
      </Link>;
    } else {
      accountButton = <AccountMainMenu></AccountMainMenu>;
    }
  } else {
    if (!customerToken) {
      accountButton = <Link to={"/login"} style={{textDecoration: "none"}}>
        <IconButton ><LoginIcon sx={{height:20, color:"#000"}} /></IconButton>
      </Link>;
    } else {
      accountButton = <AccountMainMenu></AccountMainMenu>;
    }
  }
  //console.log(cartTotal);

  return(
    <Column width="100%" height={height} center>
      <div style={{display: searchField ? 'block': 'none'}}>
        <Column width={ lt.max === 4 ? lt.bunch4-20 : lt.bunch8-40} height={100} >
          <Row width="100%" height="100%" center>
            <Row left={20} sx={{flex:1}}>
              <TextField
                margin="dense"
                id="serch"
                label="Search"
                type="text"
                fullWidth
                variant="standard"
                onChange={searchFieldChangeHandler}
                inputRef={searchRef}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    searchItemsHandler();
                  }
                }}
              />
            </Row>
            <Row>
              <Row right={20} width={40} height={40}>
                <IconButton color="primary" component='div' sx={{width:"100%", height:"100%", color:'#000'}} onClick={searchItemsHandler}>
                  <SearchIcon />
                </IconButton>
              </Row>
              <Row right={5} width={40} height={40}>
                <IconButton color="primary" component='div' sx={{width:"100%", height:"100%", color:'#000'}} onClick={searchCloseHandler}>
                  <Close />
                </IconButton>
              </Row>
            </Row>
          </Row>
          <Column left={20}>
            {window.location.href.includes("/search/") === false &&
            <Stack>
              <StackItem z={100} color="rgb(250,236,236)" sx={{ boxSizing:'border-box', borderRadius:2, borderColor:"#000", borderStyle:"solid", borderWidth: searchedProducts.length > 0 ? 1: 0}}>
                <MenuList >
                  { searchedProducts.map((v,i)=>{
                    return (
                      <MenuItem key={v.uid} data-sku={v.sku} onClick={searchFormSelectHandler}>
                        <Row right={10}><img alt="" style={{borderStyle:'solid', borderWidth:1,boxSizing:'border-box', borderRadius:5, borderColor:"white"}} width={50} src={v.image.url}/></Row><Subtitle2>{v.name}</Subtitle2>
                      </MenuItem>
                    );
                    })
                  }
                </MenuList>
              </StackItem>
            </Stack>
            }
          </Column>
        </Column>
      </div>
      <div style={{display: !searchField ? 'block': 'none'}}>
      <Column width={lt.innerWidth}>
        <div style={{display: lt.max >= 8 ? 'block': 'none', width:"100%", height:"100%"}}>
          <Row main={Axis.main.between}>
            <Row><A href="/" style={{marginLeft:20}}><img alt="" src="/images/logo.png" width={100}></img></A></Row>
            <Row>
              { lt.max > 8 ?
              <>
              <Row cross={Axis.cross.center}>
                <HeaderTelephone startIcon={<Phone sx={{width:12}} />}>1 (855) 997-9701</HeaderTelephone>
              </Row>
              <Row cross={Axis.cross.center} left={20}>
                <CallBackButton>Call Back</CallBackButton>
              </Row>
              </>
              :
              <Column cross={Axis.cross.center}>
                <Row cross={Axis.cross.center}>
                  <HeaderTelephone startIcon={<Phone sx={{width:12}} />}>1 (855) 997-9701</HeaderTelephone>
                </Row>
                <Row cross={Axis.cross.center} top={5}>
                  <CallBackButton>Call Back</CallBackButton>
                </Row>
              </Column>
              } 
              <Row cross={Axis.cross.center} left={50}>
                <HeaderButton startIcon={<SearchIcon sx={{height:20}} />} onClick={searcShowhHandler}>Search</HeaderButton>
              </Row>
              <Row cross={Axis.cross.center} left={50}>
                {accountButton}
              </Row>
              <Row cross={Axis.cross.center} left={50} right={20}>
                <HeaderButton startIcon={<ShoppingBagOutlined sx={{height:20}} />} onClick={cartHandler}>Bag ({cartTotal})</HeaderButton>
              </Row>
            </Row>
          </Row>
        </div>
        <div style={{display: lt.max === 4 ? 'block': 'none'}}>
          <Column width={lt.innerWidth} cross={Axis.cross.between} >
            <Row main={Axis.main.between}>
              <Row><A href="/" style={{marginLeft:10}}><img alt="" src="/images/logo.png" width={63}></img></A></Row>
            <Row >
              <Row cross={Axis.cross.center} left={5}>
                <IconButton onClick={searcShowhHandler}><Search sx={{height:20, color:"#000"}} /></IconButton>
              </Row>
              <Row cross={Axis.cross.center} left={5}>
                {accountButton}
              </Row>
              <Row left={10} right={0}>
                <IconButton onClick={cartHandler} ><ShoppingBagOutlined sx={{height:20, color:"#000"}} /><Caption>({cartTotal})</Caption></IconButton>
              </Row>
            </Row>
          </Row>
          </Column>
        </div>
      </Column>
      </div>
    </Column>
  );
}

const MainNavButton = styled.div`
  display:flex;
  flex-direction:column;
  text-decoration:none; 
  color:#000;
  height:53px;
  padding: 0 26px;
  background-color:transparent;
  font-size:14px;
  font-weight:600;
  letter-spacing:1.9px;
  text-transform:uppercase;
  cursor:pointer;
  outline:none;
  align-items:center;
  justify-content:center;
  ${props => props.debug && `
    border-style:dashed;
    border-width:1px;
    border-color:black;`
  }
  &:hover {
    color:#6abdd2;
    background-color:#fff;
  }
`;


export const Node = styled.ul`
  padding:0px;
  margin:0px;
  ${props=>props.closed ? `display:none;`: `display:block;`}
  ${props=>props.pinRight && `
    position:absolute;
    left:100%;`
  }
  ${props => props.debug && `
    border-style:dashed;
    border-width:1px;
    border-color:black;`
  }
`;

export const NodeItem = styled.li`
  padding:0px;
  margin:0px;
  position:relative;
  ${props => props.debug && `
    border-style:dashed;
    border-width:1px;
    border-color:black;`
  }
  ${props=>props.closed ? `display:none;`: `display:block;`}
  ${props=>props.group && `
    position:absolute;`
  }
  &:hover > ${Node} {
    display:block;
  }
`;

export const NodeRoot = styled.ul`
  padding:0px;
  margin:0px;
  position:relative;
  ${props => props.debug && `
    border-style:dashed;
    border-width:1px;
    border-color:black;`
  }
  &:hover > ${Node} {
    display:block;    
  }
`;

const SSMenuItemDecoLine = styled.div`
  transition: width 0.2s linear;
  width: 0px;
  height: 2px;
  background-color: #eec0c0;
`;

const SSMenuItemPicture = styled.div`
  border:1px solid #ebebeb;
  height:114px;
  width:167px;
  background:url("/images/${props => props.image}");
  background-size:contain;
  background-repeat:no-repeat;
`;

const SSMenuWrapper = styled.div`
  width:100%;
  cursor:pointer;
  &:hover ${SSMenuItemDecoLine} {
    width:30px;
    transition: width 0.2s linear;
  }
`;

const SSMenuItemTitle = styled.div`
  font-size:14px;
  font-weight:500;
  line-height:1;
  text-transform: uppercase;
  color:#000;
  position:relative;
  margin-bottom:5px;
  ${SSMenuWrapper}:hover & {
    color:#6abdd2;
  }
`;

const SSMenuItemBody = styled.div`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  font-weight: normal;
  text-transform: none;
  margin-top:12px;
  ${SSMenuWrapper}:hover & {
    color:#6abdd2;
  }
`;





/* example of menu, don't delete

<NodeRoot height={53}>
  <MainNavButton height={53}>Special services</MainNavButton>
  <Node closed style={{position:'absolute', top:53, zIndex:2, minWidth:"100%"}}>
    <NodeItem style={{width:"100%"}} debug><Row height={44}><Subtitle1>iMac</Subtitle1></Row></NodeItem>
    <NodeItem group debug style={{width:"100%"}}> 
      <Row height={44}><Subtitle1>iPhone</Subtitle1></Row>
      <Node closed pinRight>
        <NodeItem debug><Row height={44}><Subtitle1>XS</Subtitle1></Row></NodeItem>
        <NodeItem debug><Row height={44}><Subtitle1>Max Max</Subtitle1></Row></NodeItem>
      </Node>
    </NodeItem>
  </Node>
</NodeRoot>
*/

export function MainNav(){
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const navigate = useNavigate();

  const spec3dwaxHandler = () => {
    navigate("/spec3dwax");
  }

  const specDesignHandler = () => {
    navigate("/specdesign");
  }

  const onMenuClick = ()=>{
    navigate("/mobilmenu");
  }

  const onProductClick = ()=>{
    navigate("/category");
  }

  const onAboutClick = ()=>{
    navigate("/about");
  }

  const onNewStylesClick = ()=>{
    navigate("/newstyles");
  }

  const onContactClick = ()=>{
    navigate("/contacts");
  }

  const onFaqClick = ()=>{
    navigate("/faq");
  }

  return(
    <Column width="100%" height={53} color={Color.panelColor} center>
      <Column width={lt.innerWidth}>
        <div style={{display: (lt.max === 16 || lt.max === 12) ? 'block': 'none'}}>
          <Row main={Axis.main.between}>
            <Row left={20} >
              <MainNavButton height={53} onClick={onProductClick}>Products</MainNavButton>
            </Row>
            <Row right={20}>
              <Column>
                <NodeRoot height={53}>
                  <MainNavButton height={53}>Special services</MainNavButton>
                  <Node closed style={{position:'absolute', top:53, zIndex:2, minWidth:"100%"}}>
                    <NodeItem style={{width:"100%", paddingLeft:20, paddingBottom:10, backgroundColor:'white'}} >
                      <SSMenuWrapper>
                        <Column width={350}>
                          <Divider light={true} />
                          <Row top={10} bottom={5}>
                            <Column  width={350/2} onClick={spec3dwaxHandler}>
                              <SSMenuItemTitle><Box whiteSpace="normal" sx={{marginRight:"5px"}}>3D wax print</Box></SSMenuItemTitle>
                              <SSMenuItemDecoLine />
                              <SSMenuItemBody><Box whiteSpace="normal" sx={{marginRight:"5px"}}>3D Wax print for $18 within 24 hours!</Box></SSMenuItemBody>
                            </Column>
                            <Column  width={350/2} cross={Axis.cross.center} main={Axis.main.start}>
                              <SSMenuItemPicture image="land1.jpg"></SSMenuItemPicture>
                            </Column>
                          </Row>
                        </Column>
                      </SSMenuWrapper>
                    </NodeItem>
                    <NodeItem style={{width:"100%", paddingLeft:20, paddingBottom:10, backgroundColor:'white'}} >
                      <SSMenuWrapper>
                        <Column width={350}>
                          <Divider light={true} />
                          <Row top={10} bottom={5}>
                            <Column  width={350/2} onClick={specDesignHandler}>
                              <SSMenuItemTitle><Box whiteSpace="normal" sx={{marginRight:"5px"}}>Make your own design</Box></SSMenuItemTitle>
                              <SSMenuItemDecoLine />
                              <SSMenuItemBody><Box whiteSpace="normal" sx={{marginRight:"5px"}}>Make your own design with us starting from $59 only!</Box></SSMenuItemBody>
                            </Column>
                            <Column  width={350/2} cross={Axis.cross.center} main={Axis.main.start}>
                              <SSMenuItemPicture image="land2.jpg"></SSMenuItemPicture>
                            </Column>
                          </Row>
                        </Column>
                      </SSMenuWrapper>
                    </NodeItem>
                  </Node>
                </NodeRoot>
              </Column>
              <MainNavButton height={53} onClick={onNewStylesClick}>New styles</MainNavButton>
              <MainNavButton height={53} onClick={onAboutClick}>About us</MainNavButton>
              <MainNavButton height={53} onClick={onContactClick}>Contact us</MainNavButton>
              <MainNavButton height={53} onClick={onFaqClick}>Faq</MainNavButton>
            </Row>
          </Row>
        </div>
        <div style={{display: (lt.max === 8 || lt.max === 4) ? 'block': 'none'}}>
          <Row left={10}>
            <IconButton onClick={onMenuClick}><MenuIcon></MenuIcon></IconButton>
          </Row>
        </div>
      </Column>
    </Column>
  );
}

export function Dbg() {
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;

  const [height, setHeight] = useState(20);

  let body = [];
  for (let i = 0; i < lt.max; i++) {
    body.push(
      <Column center key={i} width={lt.col + lt.gutter} height={height} color={'rgba(80, 102, 161, 0.3)'} sx={{ transition: "height 0.3s ease-in-out" }}>
        <Column width={lt.col} height={height} color={'rgba(240, 113, 121, 0.3)'} sx={{ transition: "height 0.3s ease-in-out" }}></Column>
      </Column>
    );
  }
  
  return(
    <Stack width='100%' onClick={()=>{
      if (height === 20) {
        setHeight(window.document.documentElement.scrollHeight);
      } else {
        setHeight(20);
      }
    }}>
      <StackItem width='100%' z={1000}>
        <Row center>{body}</Row>
      </StackItem>
    </Stack>
  );
}

