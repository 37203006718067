import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import { Row, Column, Axis, Stack, StackItem } from './layout';
import { Color } from './flatColors';
import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import styled from "@emotion/styled/macro";

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Rhino3dmLoader } from 'three/examples/jsm/loaders/3DMLoader';

import async from 'async';

var _ = require('lodash');
let settings = {};

export function Viewer(props) {
  const stateObj = useSelector((state) => state.mainState);
  const query = new URLSearchParams(window.location.search);
  const sku = query.get('sku');

  const [ metalColor, setMetalColor ] = React.useState("#D8D8D8");

  useEffect(()=>{

    removeAllChildNodes(window.document.getElementById("canvas"));

    function animate() {
      let renderer = settings.renderer;
      //let camera = settings.camera;
      //let scene = settings.scene;
      if (renderer) {
        //console.log('animate');
        //requestRef.current = requestAnimationFrame(animate);
        requestAnimationFrame( animate );
        settings.renderer.render( settings.scene, settings.camera );
      }
    }
    
    function onWindowResize(){
      let renderer = settings.renderer;
      //let camera = settings.camera;
      if (renderer) {
          settings.camera.aspect = window.innerWidth / window.innerHeight;
          settings.camera.updateProjectionMatrix();
          settings.renderer.setSize( window.innerWidth, window.innerHeight );
          animate();
      }
    }

    let loader = new Rhino3dmLoader();
    loader.setLibraryPath( 'https://cdn.jsdelivr.net/npm/rhino3dm@7.11.1/');
    loader.setCrossOrigin('anonymous');
    let scene = new THREE.Scene();
    scene.background = loadCubeMap('Room', '', 'png');
    //scene.background = new THREE.Color( 0xffffff );
    let camera = new THREE.PerspectiveCamera( 35, window.innerWidth/window.innerHeight, 1, 10000 );
    camera.position.z = 50;

    let light = new THREE.DirectionalLight(0xffffff, 1.0);
    light.position.set(-10, 10, 0);
    camera.add( light );
    scene.add(camera);

    const light1 = new THREE.PointLight(0xffffff, 0.2);
    light1.position.set(5, 1, 0);
    scene.add(light1);
    const light2 = new THREE.PointLight(0xffffff, 0.2);
    light2.position.set(5,1,0);
    scene.add(light2);
    const light3 = new THREE.PointLight(0xffffff, 0.2);
    light3.position.set(0,1,-5);
    scene.add(light3);
    const light4 = new THREE.PointLight(0xffffff, 0.2);
    light4.position.set(-5,3,5);
    scene.add(light4);

    let renderer = new THREE.WebGLRenderer({antialias: true});
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.shadowMap.enabled = true;

    settings = {"scene": scene, "camera": camera, "renderer": renderer, "light": light, "loader": loader};

    const query = `{ products(filter: {sku: {eq: "${sku}"}}) { items { attr_3d_model } } }`;
    axios({url: stateObj.api, method: 'post', data: { query: query }}).then((result)=>{
      if (!result.data.hasOwnProperty("errors")) {
        try {
          let attr_3d_model = result.data.data.products.items[0].attr_3d_model;
          if (attr_3d_model) {
            let files = [];
            const t = attr_3d_model.split(",");
            t.map((v,i)=>{
              files.push(v.trim());
              return null;
            });
            const headers = {
              'Cache-Control': 'no-cache, no-store, must-revalidate',
              'Pragma': 'no-cache',
              'Expires': '0',
            };
            const query = {
              "command":"get_files_from_storage",
              "files":files,
            };
            axios({url: stateObj.apiRest, method: 'post', headers: headers, data: query}).then((result)=>{
              if (Array.isArray(result.data)) {
                //console.log(result.data);
                let files3D = result.data;
                clear();
                let canvas = document.getElementById("canvas");
                canvas.appendChild(settings.renderer.domElement);
                const controls = new OrbitControls( camera, renderer.domElement  );
                window.addEventListener('resize', onWindowResize, false);
                //console.log('run animate');
                animate();
                
                let gemFile = null;
                let metalFile = null;
                if (Array.isArray(files3D)) {
                  files3D.map((v,i)=>{
                    if (v.includes("GEM_")) {
                      gemFile = v;
                    } else if (v.includes("METAL_")) {
                      metalFile = v;
                    } else {
                      metalFile = v;
                    }
                    return null;
                  });
                }
                
                //console.log(gemFile);
                //console.log(metalFile);
                if (files3D.length > 0) {
                  loadFullModel(metalFile, gemFile, false, metalColor);
                }
              }
            });
            
          }
        } catch(error) {
          console.log(error);
        }
      } 
    }).catch((error)=>{
      console.log(error);
    });
  },[metalColor]);  

  function removeAllChildNodes(parent) {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  }

  function clear() {
    const $ = window.$;
    $('#progress').css('display','none');

    if (_.isEmpty(settings)) {
      return;
    }
    let scene = settings.scene;
    let children = [];
    //console.log('clearing scene');
    if (scene === undefined) {
      return;
    }
    scene.traverse((child)=>{
      children.push(child);
      //console.log(child)
    })
    for (let i = 0;i<children.length;i++) {
      let child = children[i];
      if (child.type === "Mesh") {
        child.geometry.dispose();
        child.material.dispose();
        scene.remove(child);
        //console.log('delete mesh')
      } else if (child.type === 'Object3D' && child.name.includes(".3dm")) {
        //console.log('delete obj')
        scene.remove(child);
      } else if (child.type === 'Group') {
        //scene.remove(child)
        for (let k=0;k<child.children.length;k++) {
          let node = child.children[k];
          if (node.type === 'Mesh') {
            node.geometry.dispose();
            node.material.dispose();
            child.remove(node);
          }
        }
        scene.remove(child);
      }
    }
  }
    
  function loadFullModel(modelMetal, modelGem, withCoin, metalColor) {
    if (!settings.renderer) {
      return;
    }

    const $ = window.$;
    $('#progress').css('display','block');
    async.parallel([
      function(callback) {
        loadMetal(modelMetal, withCoin, metalColor, callback);
      },
      function(callback) {
        
        loadGems(modelGem, callback);
        

      },
    ], (err, results)=>{
      //console.log('finish', results);
      //setProgress(0);
      $('#progress').css('display','none');
    });
    
  }
    
  function loadMetal(model, withCoin, metalColor, callback) {
    if (!settings.loader) {
      callback(null, false);
      return;
    }
    if (!model) {
      callback(null, false);
      return;
    }

    //console.log(model);
    
    let scene = settings.scene;
    let loader = settings.loader;
    const material = new THREE.MeshPhysicalMaterial();
    //loadPBRMaterial(material, 'gold-metal')
    //material.color = new THREE.Color(0xffcc88); // 0xffcc88
    //material.color = new THREE.Color(0xD8D8D8); // 0xffcc88
    material.color = new THREE.Color(metalColor);
    material.metalness = 0.9;
    material.roughness = 0.0;
    material.clearcoat = 0.7;
    material.reflectivity = 0.9;
    material.normalScale.x = 1.0;
    material.normalScale.y = 1.0;
    material.envMap = scene.background;
    
    const $ = window.$;
    
    async.parallel([
      function(callback) {
        loader.load(model, (object ) => {
          object.traverse( function (child) {
              child.rotateX( - Math.PI / 4 )
              child.material = material
          });
          scene.add( object );
          callback(null, true);
        }, (data)=>{ 
          let value = parseInt((data.loaded/data.total)*100);
          //setProgress(value);
          $('#progress').text(`${value}%`);
          //console.log(value);
        }, (error)=>{})
      },
      function(callback) {
          loadCoin('/quarter.3dm', withCoin, callback);
      }
    ], function(err, results) {
        callback(null, results);
    });  
  }
  
  function loadGems(model, callback) {
    if (!settings.loader) {
      callback(null, false);
      return;
    }
    if (!model) {
      callback(null, false);
      return;
    }
    let scene = settings.scene;
    let loader = settings.loader;
    const ld = new THREE.TextureLoader();
    ld.load( "textures/Gem_Diamond.jpg", (texture)=>{        
        const materialr = new THREE.MeshPhysicalMaterial({
            /*metalness: 0.5,
            reflectivity:0.7,
            clearcoat:1,
            clearcoatRoughness:0.25,*/
            color: new THREE.Color(0xee0000),
            map: texture,
            transparent: true, 
            refractionRatio: 0.9,
            blending: THREE.AdditiveBlending,
        });
        const materialg = new THREE.MeshPhysicalMaterial({
            /*metalness: 0.5,
            reflectivity:0.7,
            clearcoat:1,
            clearcoatRoughness:0.25,*/
            color: new THREE.Color(0x00ff00),
            map: texture,
            transparent: true, 
            refractionRatio: 0.9-0.005,
            blending: THREE.AdditiveBlending,
        });
        const materialb = new THREE.MeshPhysicalMaterial({
            /*metalness: 0.5,
            reflectivity:0.7,
            clearcoat:1,
            clearcoatRoughness:0.25,*/
            color: new THREE.Color(0x0000dd),
            map: texture,
            transparent: true, 
            refractionRatio: 0.9-0.01,
            blending: THREE.AdditiveBlending,
        });
        loader.load( model, (object) => {
            object.traverse( function (child) {
                //child.rotateX( - Math.PI / 4 )
                //child.material = mmm
                const heartr = new THREE.Mesh(child.geometry, materialr);
                const heartb = new THREE.Mesh(heartr.geometry, materialg);
                const heartg = new THREE.Mesh(heartr.geometry, materialb);
                const heart = new THREE.Group();
                heart.add(heartr); heart.add(heartg); heart.add(heartb); 
                heart.rotateX(-Math.PI / 2)
                scene.add(heart)
            })
            //scene.add(object)
            callback(null, true);
        }, (data)=>{ 

        }, (error)=> {
            console.log(error);
            callback(null, true);
        });
    }, undefined, 
    (error)=>{
        callback(error, true);
    });
  }
    
    
    
  function loadCoin(model, withCoin, callback) {
      let scene = settings.scene;
      let loader = settings.loader;
      if (withCoin === false) {
          callback(null, true);
          return;
      }
      const material = new THREE.MeshPhysicalMaterial();
      loadPBRMaterial(material, 'streaked-metal1');
      material.metalness = 0.75;
      material.roughness = 0.2;
      material.normalScale.x = 1.0;
      material.normalScale.y = 1.0;
      material.envMap = scene.background;
      
      loader.load(model, function ( object ) {
          object.traverse( function (child) {
              child.rotateX( - Math.PI / 4 );
              child.material = material;
          })
          object.position.set(0,0,-5);
          scene.add( object );
          callback(null, true);
      }, (data)=>{ 
          
      }, (error)=> {
          console.log(error);
          callback(null, true);
      })
  }
  
  const metalColorSelect = (event)=>{
    const color = event.currentTarget.dataset.color;
    console.log(color);
    clear();
    setMetalColor(color);
  }

  return(
    <Column width="100vw">
      <Stack>
        <StackItem>
          <div id="canvas"></div>
        </StackItem> 
        <StackItem>
          <Row left={20} top={20}>
            <MetalStyle color="#D8D8D8" data-color="#D8D8D8" onClick={metalColorSelect}></MetalStyle>
            <MetalStyle color="#ffcc88" data-color="#ffcc88" onClick={metalColorSelect} left={3}></MetalStyle>
            <MetalStyle color="#e8cabc" data-color="#e8cabc" onClick={metalColorSelect} left={3}></MetalStyle>
            <Row left={3}>
              <div id="progress" style={{backgroundColor: Color.mainColor, color:'white', padding:"7px 12px", fontSize:18, fontWeight:600, borderRadius:5}}></div>
            </Row>
          </Row>
        </StackItem>
      </Stack>
    </Column>
  );
}

const MetalStyle = styled(Row)`
  border-radius:20px;
  width:40px;
  height:40px;
  background-color: ${props => props.color};
  &:hover {
    cursor:pointer
  }
`;


function loadPBRMaterial(material, name) {
  let tl = new THREE.TextureLoader();

  tl.setPath('/materials/PBR/' + name + '/');
  material.map          = tl.load(name + '_base.png');
  material.aoMmap       = tl.load(name + '_ao.png');
  material.normalMap    = tl.load(name + '_normal.png');
  material.metalnessMap = tl.load(name + '_metallic.png');
  material.alphaMap = null; //tl.load(name + '_alpha.png')
}


function loadCubeMap(name, prefix, format) {
  const ctl = new THREE.CubeTextureLoader();

  ctl.setPath('/textures/cube/' + name + '/');
  return ctl.load([prefix + 'px.' + format, prefix + 'nx.' + format, prefix + 'py.' + format,
                      prefix + 'ny.' + format, prefix + 'pz.' + format, prefix + 'nz.' + format]);
}