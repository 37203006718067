import { ConstructionOutlined } from '@mui/icons-material';
import axios from 'axios';

import { 
  SET_CALCULATED_LAYOUT, 
  SET_VALUE, 
  LOG_IN, 
  LOG_OUT, 
  GET_PRODUCT_INFO, 
  ADD_PRODUCT_TO_CART, 
  SET_ERROR, 
  GET_CART,
  SET_CART_TOTAL,
  CLEAR_ALL_ERRORS,
  SET_SHIPPING_ADDRESS,
  SET_BILLING_ADDRESS,
  RESET_ADDRESSES_ON_CART,
  GET_BRAINTREE_TOKEN,
  PLACE_ORDER,
  PLACE_ORDER_OF_SALES_MANAGER,
  GET_CATEGORY_TREE,
  GET_CATEGORY_INFO,
  GET_COUNTRIES,
  GET_ADDRESSES,
  SET_ADDRESSES_ON_CART,
  CREATE_CUSTOMER_ADDRESS,
  DELETE_CUSTOMER_ADDRESS,
  SET_CUSTOMER_ADDRESS_AS_DEFAULT,
  REMOVE_ITEM_FROM_CART,
  CREATE_CUSTOMER,
  RESET_STATE,
  ACTIVATE_NEW_CUSTOMER,
  GET_FILES_FROM_STORAGE,
  SEARCH_PRODUCTS,
  GET_CUSTOMER_ORDERS,
  GET_CUSTOMER_ORDER_INFO,
  GET_CUSTOMER_INFO,
  GET_PRODUCTS_LIST,
  REQUEST_PASSWORD_RESET_EMAIL,
  RESET_PASSWORD,
  ERR_TYPE_CREATE_CART, 
  ERR_TYPE_CUSTOMER_LOGIN, 
  ERR_TYPE_ADD_PRODUCT_TO_CART,
  ERR_TYPE_GET_CART,
  ERR_TYPE_SET_SHIPPING_ADDRESS,
  ERR_TYPE_SET_BILLING_ADDRESS,
  ERR_TYPE_GET_BRAINTREE_TOKEN,
  ERR_TYPE_PLACE_ORDER,
  ERR_TYPE_GET_CATEGORY_TREE,
  ERR_TYPE_PLACE_ORDER_OF_SALES_MANAGER,
  ERR_TYPE_GET_COUNTRIES,
  ERR_TYPE_GET_ADDRESSES,
  ERR_TYPE_CREATE_CUSTOMER_ADDRESS,
  ERR_TYPE_DELETE_CUSTOMER_ADDRESS,
  ERR_TYPE_SET_CUSTOMER_ADDRESS_AS_DEFAULT,
  ERR_TYPE_REMOVE_ITEM_FROM_CART,
  ERR_TYPE_CREATE_CUSTOMER,
  SELECT_SHIPPING_ADDRESS_ID,
  SELECT_BILLING_ADDRESS_ID,
  ERR_TYPE_GET_CUSTOMER_ORDERS,
  ERR_TYPE_SEARCH_PRODUCTS,
  ERR_TYPE_GET_PRODUCT_INFO,
  ERR_TYPE_ACTIVATE_NEW_CUSTOMER,
  ERR_TYPE_GET_FILES_FROM_STORAGE,
  ERR_TYPE_GET_CATEGORY_INFO,
  ERR_TYPE_GET_PRODUCTS_LIST,
  ERR_TYPE_GET_CUSTOMER_ORDER_INFO,
  ERR_TYPE_GET_CUSTOMER_INFO,
  ERR_TYPE_REQUEST_PASSWORD_RESET_EMAIL,
  ERR_TYPE_RESET_PASSWORD,
} from "./types";

var _ = require('lodash');
var he = require('he');

export const clearAllErrors = () => (dispatch, getState) => {
  try {
    dispatch({
      type: CLEAR_ALL_ERRORS,
      payload: {
        errMessage: "",
        errType:"",
        successMessage: "",
      },
    });
  } catch (error) {
    console.log("Error", error);
  }
};

export const setError = (errMessage, errType) => (dispatch, getState) => {
  try {
    dispatch({
      type: SET_ERROR,
      payload: {
        errMessage: errMessage,
        errType:errType,
      },
    });
  } catch (error) {
    console.log("Error", error);
  }
};

export const resetState = (payload) => (dispatch, getState) => {
  //console.log("reset state", payload);
  try {
    dispatch({
      type: RESET_STATE,
      payload: payload,
    });
  } catch (error) {
    console.log("Error", error);
  }
};


export const updateCalculatedLayout = (lt) => (dispatch, getState) => {
  try {
    //console.log(lt, getState());
    dispatch({
      type: SET_CALCULATED_LAYOUT,
      payload: {
        lt: lt,
        errMessage: ""
      },
    });
  } catch (error) {
    console.log("Error", error);
  }
};

export const logInMagento = (email, password) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let query = `
      mutation {
        generateCustomerToken(email: "${email}", password: "${password}") {
        token
      }
    }
    `;
    let result = await axios({url: state.api,method: 'post',data: {query: query}});
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          userEmail:"",
          userPassword:"",
          loginResult:false,
          errType: ERR_TYPE_CUSTOMER_LOGIN,
        },
      });
    } else {
      const data = result.data.data;
      const customerToken = data.generateCustomerToken.token;
      query = `{ customer { email } }`;
      let headers = { 'Authorization': `Bearer ${customerToken}`};
      result = await axios({url: state.api,method: 'post', headers: headers, data: {query: query}});
      const email = result.data.data.customer.email;
      //console.log(email);
      dispatch({
        type: LOG_IN,
        payload: {
          customerToken: customerToken,
          customerEmail: email,
          userEmail: email,
          userPassword: password,
          loginResult:true,
          errMessage: "",
          errType: ""
        },
      });
    }
  } catch (error) {
    console.log("Error", error);
  }
};

export const logOutMagento = () => (dispatch, getState) => {
  try {
    dispatch({
      type: LOG_OUT,
      payload: {
        customerToken: "",
        errMessage: "",
        errType:"",
        userEmail:"",
        userPassword:"",
        loginResult:false,
        /*successMessage: "",
        productInfo: {},
        cart_id: "",
        cartTotal: 0, //qty
        cartGrandTotal: 0, //sum
        cart: {},
        shippingAddress: {},
        billingAddress: {},
        braintreeToken: "",
        placedOrder: {},
        categoryTree: {},
        appliedCoupons: null,
        isSalesManager: false, //if customer is a sales manager
        countries: [],
        selectedShippingAddressId: null,
        selectedBillingAddressId: null,
        addresses: [],
        customerCreated: "",
        customerOrders: {},
        searchedProducts: [],*/
      },
    });
  } catch (error) {
    console.log("Error", error);
  }
};

export const createCustomer = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    const query = `
    mutation {
      createCustomer (input: {
        firstname: "${payload.firstname}"
        lastname:"${payload.lastname}"
        email: "${payload.email}"
        password: "${payload.password}"
        is_subscribed: false
      })
      {
        customer {
          email
        }
      }
    }
    `;
    let result = await axios({url: state.api,method: 'post',data: {query: query}});
    console.log(result.data);
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          userEmail:"",
          userPassword:"",
          errType: ERR_TYPE_CREATE_CUSTOMER,
        },
      });
    } else {
      const data = result.data.data;
      dispatch({
        type: CREATE_CUSTOMER,
        payload: {
          errMessage: "",
          errType: "",
          customerCreated: data.createCustomer.customer.email,
        },
      });
    }
  } catch (error) {
    console.log("Error", error);
  }
};

export const requestPasswordResetEmail = (email) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    const query = `
    mutation {
      requestPasswordResetEmail(email:"${email}")
    }
    `;
    let result = await axios({url: state.api,method: 'post',data: {query: query}});
    console.log(result.data);
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          userEmail:"",
          userPassword:"",
          requestPasswordResetEmailResult:false,
          errType: ERR_TYPE_REQUEST_PASSWORD_RESET_EMAIL,
        },
      });
    } else {
      const data = result.data.data;
      dispatch({
        type: REQUEST_PASSWORD_RESET_EMAIL,
        payload: {
          requestPasswordResetEmailResult:true,
        },
      });
    }
  } catch (error) {
    console.log("Error", error);
  }
};

export const resetPassword = (email, token, newPassword) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    const query = `
    mutation {
      resetPassword(email:"${email}", resetPasswordToken:"${token}", newPassword:"${newPassword}")
    }
    `;
    let result = await axios({url: state.api,method: 'post',data: {query: query}});
    //console.log(result.data);
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          errType: ERR_TYPE_RESET_PASSWORD,
          resetPasswordResult: false,
        },
      });
    } else {
      const data = result.data.data;
      dispatch({
        type: RESET_PASSWORD,
        payload: {
          resetPasswordResult: true
        },
      });
    }
  } catch (error) {
    console.log("Error", error);
  }
};




export const getCustomerOrderInfo = (customerToken, number) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = { 'Authorization': `Bearer ${customerToken}`};
    let query = `
    {
      customer {
        orders(currentPage:1, pageSize:10, filter: { number: { eq: "${number}"}}) {
          total_count
          page_info {
            current_page
            page_size
            total_pages
          }
          
          items {
            id
            number
            status
            comments { 
              message
              timestamp
            }
            order_date
            total {
              grand_total {
                currency
                value
              }
              discounts {
                label
                amount {
                  currency
                  value
                }
              }
              subtotal {
                currency
                value
              }
              taxes {
                title
                rate
                amount {
                  currency
                  value
                }
              }
              total_tax {
                currency
                value
              }
              total_shipping {
                currency
                value
              }
            }
            items {
              id
              product_sku
              product_name
              quantity_ordered
              selected_options {
                label
                value
                
              }
              entered_options {
                label
                value
              }
            }
          }
        }
      }
    }
    `;
    //console.log(query);
    let result = await axios({url: state.api,method: 'post', headers: headers, data: {query: query}});
    //console.log(result);
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          customerOrderInfo:{},
          errType: ERR_TYPE_GET_CUSTOMER_ORDER_INFO,
        },
      });
    } else {
      let data = result.data.data.customer.orders.items[0];
      //console.log(data);
      dispatch({
        type: GET_CUSTOMER_ORDER_INFO,
        payload: {
          errMessage: "",
          errType: "",
          customerOrderInfo: data,
        },
      });
    }
  } catch (error) {
    console.log("Error", error);
  }
}


export const getSearchedOrders = (customerToken, currentPage, searchText, customerGroup, customerEmail) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = { 'Authorization': `Bearer ${customerToken}`};
    let query = "";
    if (!customerEmail) {
      query = `
      {
        salesOrders(searchText:"${searchText}", currentPage:${currentPage}, pageSize:10, customerGroup:${customerGroup}) {
          totalRows
          items {
            number
            date
            label
            product_options
          }
        }
      }
      `;
    } else {
      query = `
      {
        salesOrders(searchText:"${searchText}", currentPage:${currentPage}, pageSize:10, customerGroup:${customerGroup}, customerEmail:"${customerEmail}") {
          totalRows
          items {
            number
            date
            label
            product_options
          }
        }
      }
      `;
    }
    //console.log(query);
    let result = await axios({url: state.api,method: 'post', headers: headers, data: {query: query}});
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          customerOrders:{},
          errType: ERR_TYPE_GET_CUSTOMER_ORDERS,
        },
      });
    } else {
      let data = result.data.data;
      //const total_count = parseInt(data.salesOrders.totalRows);
      
      dispatch({
        type: GET_CUSTOMER_ORDERS,
        payload: {
          errMessage: "",
          errType: "",
          searchedOrders: data.salesOrders,
        },
      });
    }
  } catch (error) {
    console.log("Error", error);
  }
};

export const getCustomerInfo = (customerToken) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = { 'Authorization': `Bearer ${customerToken}`};
    let result = await axios({url: state.apiRestV1+"/customers/me",method: 'get', headers: headers});
    if (result.hasOwnProperty("data")) {
      dispatch({
        type: GET_CUSTOMER_INFO,
        payload: {
          errMessage: "",
          errType: "",
          customerInfo: result.data,
        },
      });
    } else {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: ERR_TYPE_GET_CUSTOMER_INFO,
          customerInfo:{},
          errType: ERR_TYPE_GET_CUSTOMER_INFO,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: {
        errMessage: ERR_TYPE_GET_CUSTOMER_INFO,
        customerInfo:{},
        errType: ERR_TYPE_GET_CUSTOMER_INFO,
      },
    });
    console.log("Error", error);
  }
};


export const getProductInfo = (sku) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let query = {"command":"get_customer_data"};
    console.log(state.apiRest);
    let result = await axios({url: state.apiRest, method: 'post', data: query});
    const customerDataFromGoogleSheet = result.data;
    query = `
    {
      products(filter: {sku: {eq: "${sku}"}})
      {
        items {
          uid,
          sku,
          name,
          attr_3d_model,
          description {
            html
          }
          short_description {
            html
          }
          price_range {
            maximum_price {
              final_price {
                currency
                value
              }
            }
          }
          image {
            url
            label
          }
          media_gallery {
            url
            label
            position
          }
          __typename
          ... on CustomizableProductInterface {
            options {
              title
              uid
              option_id
              ... on CustomizableDropDownOption {
                __typename
                value {
                  title,
                  option_type_id,
                }
              }
              ...on CustomizableAreaOption {
                __typename
                  title
              }
            }
          }
        }
      }
    }
    `;
    axios({
      url: state.api,
      method: 'post',
      data: {
        query: query
      }
    }).then((result)=>{
      if (result.data.hasOwnProperty("errors")) {
        console.log(result.data.errors);
        dispatch({
          type: SET_ERROR,
          payload: {
            errMessage: String(result.data.errors[0].message),
            errType: ERR_TYPE_GET_PRODUCT_INFO,
            productInfo: {},
          },
        });
      } else {
        const data = result.data.data;
        //console.log(data);
        dispatch({
          type: GET_PRODUCT_INFO,
          payload: {
            productInfo: data.products.items[0],
            customerDataFromGoogleSheet: customerDataFromGoogleSheet,
          },
        });
      }
    }).catch((error)=>{
      dispatch({
        type: SET_ERROR,
        payload: {
          productInfo: {},
          errMessage: String(error.message),
          errType: ERR_TYPE_GET_PRODUCT_INFO,
        },
      });
    });
    
  } catch (error) {
    console.log("Error", error);
    dispatch({
      type: SET_ERROR,
      payload: {
        productInfo: {},
        errMessage: String(error.message),
        errType: ERR_TYPE_GET_PRODUCT_INFO,
      },
    });
  }
};


export const getProductsList = (category_uid, current_page) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let query = `
    {
      categories(filters: {
        category_uid: {
          eq: "${category_uid}"
        }
      })
      {
        items {
          uid
          name
          breadcrumbs {
            category_uid
            category_name
            category_level
          }
        }
      }
    }
    `;
    let categoryInfo = {};
    let result = await axios({url: state.api, method: 'post', data: {query: query}});
    if (result.data.hasOwnProperty("errors")) {
      console.log(result.data.errors);
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          errType: ERR_TYPE_GET_PRODUCTS_LIST,
          productsList: {},
        },
      });
    } else {
      categoryInfo = result.data.data.categories.items[0];
      query = `
      {
        products(filter: { 
          category_uid: {
            eq: "${category_uid}"
          } 
        }
          pageSize:20
          currentPage:${current_page}
        ) {
          total_count
          page_info {
            current_page
            page_size
            total_pages
          }
          items {
            sku
            uid
            attr_3d_model
            name
            categories {
              uid
              name
              breadcrumbs {
                category_uid
                category_name
                category_level
              }
            }
            description {
              html
            }
            short_description {
              html
            }
            image {
              label
              url
            }
            price_range {
              maximum_price {
                final_price {
                  currency
                  value
                }
                regular_price {
                  currency
                  value
                }
                discount {
                  amount_off
                  percent_off
                }
                fixed_product_taxes {
                  amount {
                    value
                    currency
                  }
                  label
                }
              }
            }
          }
        }
      }
      `;
      result = await axios({url: state.api,method: 'post',data: {query: query}});
      if (result.data.hasOwnProperty("errors")) {
        console.log(result.data.errors);
        dispatch({
          type: SET_ERROR,
          payload: {
            errMessage: String(result.data.errors[0].message),
            errType: ERR_TYPE_GET_PRODUCTS_LIST,
            productsList: {},
          },
        });
      } else {
        const data = result.data.data;
        data.products.category_info = categoryInfo;
        //console.log(data);
        dispatch({
          type: GET_PRODUCTS_LIST,
          payload: {
            productsList: data.products,
          },
        });
      }
    }
  } catch (error) {
    console.log("Error", error);
    dispatch({
      type: SET_ERROR,
      payload: {
        productsList: {},
        errMessage: String(error.message),
        errType: ERR_TYPE_GET_PRODUCTS_LIST,
      },
    });
  }
};


export const addProductToCart = (product, customerToken) => (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = { 'Authorization': `Bearer ${customerToken}`};
    let query = `
    {
      customerCart {
        id
      }
    }
    `;
    if (customerToken === "") {
      headers = {};
      query = `
      mutation {
        createEmptyCart
      }
      `;
    } 
    axios({
      url: state.api,
      method: 'post',
      headers: headers,
      data: {
        query: query
      }
    }).then((result)=>{
      console.log(result);
      if (result.data.hasOwnProperty("errors")) {
        console.log(result.data.errors);
        dispatch({
          type: SET_ERROR,
          payload: {
            errMessage: String(result.data.errors[0].message),
            errType: ERR_TYPE_CREATE_CART,
          },
        });
      } else {
        const data = result.data.data;
        console.log(data);
        let cart_id = "";
        if (customerToken === "") {
          cart_id = data.createEmptyCart;
        } else {
          cart_id = data.customerCart.id;
        }
        let custOptions = [];
        product.custOptions.map((v, i)=>{
          custOptions.push({id: v.id, value_string: v.value_string });
        });
        let co = JSON.stringify(custOptions);
        co.replace(/\\"/g,"\uFFFF");  // U+ FFFF
        co = co.replace(/"([^"]+)":/g, '$1:').replace(/\uFFFF/g, '\\\"');
        query = `
        mutation {
          addSimpleProductsToCart(input: {
            cart_id: "${cart_id}",
            cart_items: [
              {
                data: {
                  sku: "${product.sku}",
                  quantity: ${product.qty},
                },
                customizable_options: ${co}
              }
            ]
          })
          {
            cart {
              items {
                id
                product {
                  sku
                  stock_status
                }
                quantity
                ... on SimpleCartItem {
                  customizable_options {
                    label
                    values {
                      value
                    }
                  }
                }
              }
            }
          }
        }
        `;
        //console.log(query);
        axios({
          url: state.api,
          method: 'post',
          headers: headers,
          data: {
            query: query
          }
        }).then((result)=>{
          console.log(result);
          if (result.data.hasOwnProperty("errors")) {
            console.log(result.data.errors);
            dispatch({
              type: SET_ERROR,
              payload: {
                errMessage: String(result.data.errors[0].message),
                errType: ERR_TYPE_ADD_PRODUCT_TO_CART,
              },
            });
          } else {
            const data = result.data.data;
            console.log(data);
            let num = 0;
            data.addSimpleProductsToCart.cart.items.map((v,i)=>{
              return (num += v.quantity);
            });
            dispatch({
              type: SET_CART_TOTAL,
              payload: {
                cartTotal: num,
                errMessage: "",
                errType: "",
              },
            });
          }
        }).catch((error)=>{
          console.log(error);
        });

        dispatch({
          type: ADD_PRODUCT_TO_CART,
          payload: {
            cart_id: cart_id,
            errMessage: "",
            errType: ""
          },
        });
      }
    }).catch((error)=>{
      console.log(error);
    });
  } catch (error) {
    console.log("Error", error);
  }
};


export const setCartTotal = (total) => (dispatch, getState) => {
  dispatch({
    type: SET_CART_TOTAL,
    payload: {
      cartTotal: total,
      errMessage: "",
      errType: "",
    },
  });
}

export const getCart = (customerToken) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = { 'Authorization': `Bearer ${customerToken}`};
    let query = `{ customerCart { id } } `;
    let result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          errType: ERR_TYPE_GET_CART,
        },
      });
    } else {
      let data = result.data.data;
      let cart_id = data.customerCart.id;
      //try to apply coupon for sales manager
      query = `
      mutation {
        applyCouponToCart(input: {
          cart_id: "${cart_id}",
          coupon_code: "AAA"
        }) {
          cart {
            applied_coupons {
              code
            }
          }
        }
      }
      `;  
      result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});
      //console.log(result.data);
      //get cart
      query = `
      {
        cart(cart_id: "${cart_id}") {
          id
          applied_coupons { code }
          prices {
            subtotal_excluding_tax { currency, value }
            subtotal_including_tax { currency, value }
            subtotal_with_discount_excluding_tax { currency, value }
            grand_total { currency, value }
            discounts {
              label
              amount {
                currency
                value
              }
            }
            applied_taxes {
              amount { currency, value }
              label
            }
          }
          items {
            uid
            quantity
            product {
              name
              description {
                html
              }
              image {
                label
                url
              }
              price_range {
                maximum_price {
                  final_price
                  {
                    currency
                    value
                  }
                }
              }
            }
            ... on SimpleCartItem {
              customizable_options {
                label
                type
                values {
                  value
                  label
                }
              }
            }
          }
        }
      }
      `;
      result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});
      if (result.data.hasOwnProperty("errors")) {
        dispatch({
          type: SET_ERROR,
          payload: {
            errMessage: String(result.data.errors[0].message),
            errType: ERR_TYPE_GET_CART,
          },
        });
      } else {
        data = result.data.data;
        //console.log(data);
        let grandTotal = 0;
        if (data.cart.hasOwnProperty('prices')) {
          if (data.cart.prices.hasOwnProperty('grand_total')) {
            grandTotal = data.cart.prices.grand_total.value;
          }
        }
        let isSalesManager = false;
        if (data.cart.hasOwnProperty('applied_coupons')) {
          let coupons = data.cart.applied_coupons;
          if (Array.isArray(coupons)) {
            for (let item of coupons) {
              if (item.code === "AAA") {
                isSalesManager = true;
                break;
              }
            }
          }
        }
        let customerCart = data.cart;
        //console.log(isSalesManager);

        const query = `
        {
          customer {
            addresses {
              id
              firstname
              lastname
              company
              street
              city
              region {
                region
                region_code
                region_id
              }
              region_id
              postcode
              country_code
              telephone
              default_billing
              default_shipping
            }
            
          }
        }
        `;
        result = await axios({ url: state.api, method: 'post', headers: headers, data: { query: query }});
        //console.log(result.data);
        if (result.data.hasOwnProperty("errors")) {
          dispatch({
            type: SET_ERROR,
            payload: {
              errMessage: String(result.data.errors[0].message),
              errType: ERR_TYPE_GET_ADDRESSES,
              addresses: [],
              selectedShippingAddressId: null,
              selectedBillingAddressId: null,
              shippingAddress: {},
              billingAddress: {},
            },
          });
        } else {
          const data = result.data.data;
          //console.log(data.customer);
          dispatch({
            type: GET_CART,
            payload: {
              errMessage: "",
              errType: "",
              cart: customerCart,
              cart_id: cart_id,
              cartGrandTotal: grandTotal,
              isSalesManager: isSalesManager,
              addresses: data.customer.addresses,
              selectedShippingAddressId: null,
              selectedBillingAddressId: null,
              shippingAddress: {},
              billingAddress: {},
            },
          });
        }
      }
    }
  } catch(error) {
    console.log(error);
  }
}
export const resetAddressesOnCart = () => (dispatch, getState) => {
  try {
    dispatch({
      type: RESET_ADDRESSES_ON_CART,
      payload: {
        errMessage: "",
        errType: "",
        shippingAddress: {},
        billingAddress: {},
      },
    });
  } catch(error) {
    console.log(error);
  }
}

export const setAddressesOnCart = (customerToken, cart_id, shippingAddress, billingAddress) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = { 'Authorization': `Bearer ${customerToken}`};
    let query = ``;
    if (shippingAddress.hasOwnProperty('customer_address_id')) {
      query = `
      mutation {
        setShippingAddressesOnCart(
          input: {
            cart_id: "${cart_id}"
            shipping_addresses: [
              {
                customer_address_id: ${shippingAddress.customer_address_id}
              }
            ]
          }
        ) {
          cart {
            shipping_addresses {
              firstname
              lastname
              company
              street
              city
              region {
                code
                label
              }
              postcode
              telephone
              country {
                code
                label
              }
              available_shipping_methods{
                carrier_code
                carrier_title
                method_code
                method_title
              }
            }
          }
        }
      }
      `;
    } else {
      query = `
      mutation {
        setShippingAddressesOnCart(
          input: {
            cart_id: "${cart_id}"
            shipping_addresses: [
              {
                address: {
                  firstname: ${shippingAddress.firstName}
                  lastname: ${shippingAddress.lastName}
                  company: ${shippingAddress.company}
                  street: [${shippingAddress.street}]
                  city: ${shippingAddress.city}
                  region: ${shippingAddress.regionCode}
                  postcode: ${shippingAddress.zipCode}
                  country_code: ${shippingAddress.countryCode}
                  telephone: ${shippingAddress.phone}
                  save_in_address_book: ${shippingAddress.saveForFuture}
                }
              }
            ]
          }
        ) {
          cart {
            shipping_addresses {
              firstname
              lastname
              company
              street
              city
              region {
                code
                label
              }
              postcode
              telephone
              country {
                code
                label
              }
              available_shipping_methods{
                carrier_code
                carrier_title
                method_code
                method_title
              }
            }
          }
        }
      }
      `;
    }
    let result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          errType: ERR_TYPE_SET_SHIPPING_ADDRESS,
          shippingAddress: {},
        },
      });
    } else {
      const data = result.data.data;
      const setShippingAddressesOnCart = {...data.setShippingAddressesOnCart}
      if (billingAddress.hasOwnProperty('customer_address_id')) {
        query = `
        mutation {
          setBillingAddressOnCart(
            input: {
              cart_id: "${cart_id}"
              billing_address: {
                customer_address_id: ${billingAddress.customer_address_id}
              }
            }
          ) {
            cart {
              billing_address {
                firstname
                lastname
                company
                street
                city
                region{
                  code
                  label
                }
                postcode
                telephone
                country {
                  code
                  label
                }
              }
            }
          }
        }
        `;
      } else {
        query = `
        mutation {
          setBillingAddressOnCart(
            input: {
              cart_id: "${cart_id}"
              billing_address: {
                address: {
                  firstname: ${billingAddress.firstName}
                  lastname: ${billingAddress.lastName}
                  company: ${billingAddress.company}
                  street: [${billingAddress.street}]
                  city: ${billingAddress.city}
                  region: ${billingAddress.regionCode}
                  postcode: ${billingAddress.zipCode}
                  country_code: ${billingAddress.countryCode}
                  telephone: ${billingAddress.phone}
                  save_in_address_book: ${billingAddress.saveForFuture}
                }
                same_as_shipping: ${billingAddress.same_as_shipping}
              }
            }
          ) {
            cart {
              billing_address {
                firstname
                lastname
                company
                street
                city
                region{
                  code
                  label
                }
                postcode
                telephone
                country {
                  code
                  label
                }
              }
            }
          }
        }
        `;
      }
      result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});
      if (result.data.hasOwnProperty("errors")) {
        dispatch({
          type: SET_ERROR,
          payload: {
            errMessage: String(result.data.errors[0].message),
            errType: ERR_TYPE_SET_BILLING_ADDRESS,
            billinigAddress: {},
          },
        });
      } else {
        const data = result.data.data;
        console.log(data.setBillingAddressOnCart, setShippingAddressesOnCart);
        dispatch({
          type: SET_ADDRESSES_ON_CART,
          payload: {
            errMessage: "",
            errType: "",
            shippingAddress: setShippingAddressesOnCart,
            billingAddress: data.setBillingAddressOnCart,
          },
        });
      }
    }
  } catch(error) {
    console.log(error);
  }
}



export const getBraintreeToken = (customerToken) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = { 'Authorization': `Bearer ${customerToken}`};
    let query = `
    mutation {
      createBraintreeClientToken 
    }
    `;
    let result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          errType: ERR_TYPE_GET_BRAINTREE_TOKEN,
        },
      });
    } else {
      const data = result.data.data;
      //console.log(data);
      dispatch({
        type: GET_BRAINTREE_TOKEN,
        payload: {
          errMessage: "",
          errType: "",
          braintreeToken: data.createBraintreeClientToken,
        },
      });
    }
  } catch(error) {
    console.log(error);
  }
}

export const createCustomerAddress = (customerToken, address) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    /*dispatch({
      type: CLEAR_ALL_ERRORS,
      payload: {
        errMessage: "",
        errType: "",
        successMessage: "",
      },
    });*/
    let headers = { 'Authorization': `Bearer ${customerToken}`};
    let query = `
    mutation {
      createCustomerAddress(input: {
        firstname: ${address.firstname}
        lastname: ${address.lastname}
        company: ${address.company}
        street: [${address.street}]
        city: ${address.city}
        region: {
          region_id: ${address.regionId}
        }
        postcode: ${address.zipCode}
        country_code: ${address.countryCode}
        telephone: ${address.phone}
        default_billing: ${address.default_billing},
        default_shipping: ${address.default_shipping}
      }) {
        id
      }
    }
    `;
    let result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          errType: ERR_TYPE_CREATE_CUSTOMER_ADDRESS,
        },
      });
    } else {
      query = `
      {
        customer {
          addresses {
            id
            firstname
            lastname
            company
            street
            city
            region {
              region
              region_code
              region_id
            }
            region_id
            postcode
            country_code
            telephone
            default_billing
            default_shipping
          }
          
        }
      }
      `;
      let result = await axios({ url: state.api, method: 'post', headers: headers, data: { query: query }});
      console.log(result.data);
      if (result.data.hasOwnProperty("errors")) {
        dispatch({
          type: SET_ERROR,
          payload: {
            errMessage: String(result.data.errors[0].message),
            errType: ERR_TYPE_CREATE_CUSTOMER_ADDRESS,
          },
        });
      } else {
        const data = result.data.data;
        dispatch({
          type: CREATE_CUSTOMER_ADDRESS,
          payload: {
            errMessage: "",
            errType: "",
            addresses: data.customer.addresses,
            successMessage: "New address has been created."
          },
        });
      }
    }
  } catch(error) {
    console.log(error);
  }
}

export const placeOrder = (customerToken, cart_id, nonce, payment_method) => async (dispatch, getState) => {
  //console.log(cart_id, customerToken);
  let firstName = "";
  let lastName = "";
  let phone = "";
  const state = getState().mainState;
  try {
    console.log(state.customerInfo);
    //const firstName = _.isEmpty(state.customerInfo) === false && state.customerInfo.hasOwnProperty('firstname') ? state.customerInfo.firstname : "";
    //const lastName = _.isEmpty(state.customerInfo) === false && state.customerInfo.hasOwnProperty('lastname') ? state.customerInfo.lastname : "";
    let headers = { 'Authorization': `Bearer ${customerToken}`};
    let query = `
    mutation {
      setShippingMethodsOnCart(input: {
        cart_id: "${cart_id}"
        shipping_methods: [
          {
            carrier_code: "flatrate"
            method_code: "flatrate"
          }
        ]
      }) {
        cart {
          shipping_addresses {
            firstname
            lastname
            telephone
            selected_shipping_method {
              carrier_code
              method_code
              carrier_title
              method_title
            }
          }
        }
      }
    }
    `;
    let result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          errType: ERR_TYPE_PLACE_ORDER,
        },
      });
    } else {
      //console.log(result.data.data);
      firstName = result.data.data.setShippingMethodsOnCart.cart.shipping_addresses[0].firstname;
      lastName = result.data.data.setShippingMethodsOnCart.cart.shipping_addresses[0].lastname;
      phone = result.data.data.setShippingMethodsOnCart.cart.shipping_addresses[0].telephone;
      query = `
      mutation {
        setPaymentMethodOnCart(input: {
            cart_id: "${cart_id}"
            payment_method: {
                code: "${payment_method}",
                braintree: {
                  payment_method_nonce: "${nonce}"
                  is_active_payment_token_enabler: true
              } 
            }
        }) {
          cart {
            selected_payment_method {
              code
            }
          }
        }
      }
      `;
      result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});
      if (result.data.hasOwnProperty("errors")) {
        dispatch({
          type: SET_ERROR,
          payload: {
            errMessage: String(result.data.errors[0].message),
            errType: ERR_TYPE_PLACE_ORDER,
          },
        });
      } else {
        query = `
        {
          cart(cart_id: "${cart_id}") {
            id
            applied_coupons { code }
            prices {
              subtotal_excluding_tax { currency, value }
              subtotal_including_tax { currency, value }
              subtotal_with_discount_excluding_tax { currency, value }
              grand_total { currency, value }
              discounts {
                label
                amount {
                  currency
                  value
                }
              }
              applied_taxes {
                amount { currency, value }
                label
              }
            }
            items {
              uid
              quantity
              product {
                name
                description {
                  html
                }
                image {
                  label
                  url
                }
                price_range {
                  maximum_price {
                    final_price
                    {
                      currency
                      value
                    }
                  }
                }
              }
              ... on SimpleCartItem {
                customizable_options {
                  label
                  type
                  values {
                    value
                    label
                  }
                }
              }
            }
          }
        }`;
        let rs = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});  
        query = `
        mutation {
          placeOrder(input: {cart_id: "${cart_id}"}) {
            order {
              order_number
            }
          }
        }
        `;
        result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});
        console.log(result.data);
        if (result.data.hasOwnProperty("errors")) {
          dispatch({
            type: SET_ERROR,
            payload: {
              errMessage: String(result.data.errors[0].message),
              errType: ERR_TYPE_PLACE_ORDER,
            },
          });
        } else {
          const data = result.data.data;
          //console.log(data);
          rs.data.data.order_number = data.placeOrder.order.order_number;
          // send pdf
          let number = data.placeOrder.order.order_number;
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, '0');
          var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          var yyyy = today.getFullYear();
          today = mm + '/' + dd + '/' + yyyy;
          query = {
            "command":"create_order_pdf",
            "product":rs.data.data,
            "date": today,
            "is_sales_person": false,
            "firstName": firstName,
            "lastName": lastName,
            "phone": phone,
            "currentProduct":state.currentProduct ? state.currentProduct : {}
          };
          //console.log(query);
          axios({url: state.apiRest, method: 'post', headers: headers, data: query});
          dispatch({
            type: PLACE_ORDER,
            payload: {
              errMessage: "",
              errType: "",
              placedOrder: data.placeOrder,
              cartTotal: 0,
              cartGrandTotal:0,
              nonceToken: "",
              cart:{},
              braintreeToken: "",
              shippingAddress: {},
              billingAddress: {},
              selectedShippingAddressId: null,
              selectedBillingAddressId: null,
            },
          });
        }
      }
    }

    
  } catch(error) {
    console.log(error);
  }
}


export const placeOrderOfSalesManager = (customerToken, cart_id) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = { 'Authorization': `Bearer ${customerToken}`};
    let query = `
    mutation {
      setShippingAddressesOnCart(
        input: {
          cart_id: "${cart_id}"
          shipping_addresses: [
            {
              address: {
                firstname: "JR"
                lastname: "Castings"
                company: "JR Castings"
                street: ["43 West 47th Street", "Main Floor"]
                city: "New York"
                region: "NY"
                postcode: "10036"
                country_code: "US"
                telephone: "212-997-4343"
                save_in_address_book: false
              }
            }
          ]
        }
      ) {
        cart {
          shipping_addresses {
            firstname
            lastname
            company
            street
            city
            region {
              code
              label
            }
            postcode
            telephone
            country {
              code
              label
            }
            available_shipping_methods{
              carrier_code
              carrier_title
              method_code
              method_title
            }
          }
        }
      }
    }
    `;
    //console.log(customerToken);
    //console.log(query);
    let result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});
    //console.log(result.data);
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          errType: ERR_TYPE_PLACE_ORDER_OF_SALES_MANAGER,
          placedOrder: {},
          cart: {},
          cartTotal: 0, //qty
          cartGrandTotal: 0, //sum
        },
      });
    } else {
      //console.log(result.data);
      let query = `
      mutation {
        setShippingMethodsOnCart(input: {
          cart_id: "${cart_id}"
          shipping_methods: [
            {
              carrier_code: "freeshipping"
              method_code: "freeshipping"
            }
          ]
        }) {
          cart {
            shipping_addresses {
              selected_shipping_method {
                carrier_code
                method_code
                carrier_title
                method_title
              }
            }
          }
        }
      }
      `;
      result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});
      //console.log(result.data);
      if (result.data.hasOwnProperty("errors")) {
        dispatch({
          type: SET_ERROR,
          payload: {
            errMessage: String(result.data.errors[0].message),
            errType: ERR_TYPE_PLACE_ORDER_OF_SALES_MANAGER,
            placedOrder: {},
            cart: {},
            cartTotal: 0, //qty
            cartGrandTotal: 0, //sum
          },
        });
      } else {
        query = `
        mutation {
          setBillingAddressOnCart(
            input: {
              cart_id: "${cart_id}"
              billing_address: {
                address: {
                  firstname: "JR"
                  lastname: "Castings"
                  company: "JR Castings"
                  street: ["43 West 47th Street", "Main Floor"]
                  city: "New York"
                  region: "NY"
                  postcode: "10036"
                  country_code: "US"
                  telephone: "212-997-4343"
                  save_in_address_book: false
                }
              }
            }
          ) {
            cart {
              id
            }
          }
        }
        `;
        let result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});
        if (result.data.hasOwnProperty("errors")) {
          dispatch({
            type: SET_ERROR,
            payload: {
              errMessage: String(result.data.errors[0].message),
              errType: ERR_TYPE_PLACE_ORDER_OF_SALES_MANAGER,
              placedOrder: {},
              cart: {},
              cartTotal: 0, //qty
              cartGrandTotal: 0, //sum
            },
          });
        } else {
          query = `
          mutation {
            setPaymentMethodOnCart(input: {
                cart_id: "${cart_id}"
                payment_method: {
                    code: "free",
                }
            }) {
              cart {
                selected_payment_method {
                  code
                }
              }
            }
          }
          `;
          result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});
          if (result.data.hasOwnProperty("errors")) {
            dispatch({
              type: SET_ERROR,
              payload: {
                errMessage: String(result.data.errors[0].message),
                errType: ERR_TYPE_PLACE_ORDER_OF_SALES_MANAGER,
                placedOrder: {},
                cart: {},
                cartTotal: 0, //qty
                cartGrandTotal: 0, //sum
              },
            });
          } else {
            query = `
            {
              cart(cart_id: "${cart_id}") {
                id
                applied_coupons { code }
                prices {
                  subtotal_excluding_tax { currency, value }
                  subtotal_including_tax { currency, value }
                  subtotal_with_discount_excluding_tax { currency, value }
                  grand_total { currency, value }
                  discounts {
                    label
                    amount {
                      currency
                      value
                    }
                  }
                  applied_taxes {
                    amount { currency, value }
                    label
                  }
                }
                items {
                  uid
                  quantity
                  product {
                    name
                    description {
                      html
                    }
                    image {
                      label
                      url
                    }
                    price_range {
                      maximum_price {
                        final_price
                        {
                          currency
                          value
                        }
                      }
                    }
                  }
                  ... on SimpleCartItem {
                    customizable_options {
                      label
                      type
                      values {
                        value
                        label
                      }
                    }
                  }
                }
              }
            }`;
            let rs = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});  

            query = `
            mutation {
              placeOrder(input: {cart_id: "${cart_id}"}) {
                order {
                  order_number
                }
              }
            }
            `;
            let result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});  
            //console.log(result);
            if (result.data.hasOwnProperty("errors")) {
              dispatch({
                type: SET_ERROR,
                payload: {
                  errMessage: String(result.data.errors[0].message),
                  errType: ERR_TYPE_PLACE_ORDER_OF_SALES_MANAGER,
                  placedOrder: {},
                  cart: {},
                  cartTotal: 0, //qty
                  cartGrandTotal: 0, //sum
                },
              });
            } else {
              let data = result.data.data;
              rs.data.data.order_number = data.placeOrder.order.order_number;
              // send pdf
              let number = data.placeOrder.order.order_number;
              var today = new Date();
              var dd = String(today.getDate()).padStart(2, '0');
              var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
              var yyyy = today.getFullYear();
              today = mm + '/' + dd + '/' + yyyy;
              //console.log(rs.data.data);
              for (let i=0;i<rs.data.data.cart.items.length;i++) {
                for (let k=0;k<rs.data.data.cart.items[i].customizable_options.length;k++) {
                  let label = rs.data.data.cart.items[i].customizable_options[k].label;
                  if (label === "Customer") {
                    rs.data.data.cart.items[i].customizable_options[k].values[0].value = he.decode(rs.data.data.cart.items[i].customizable_options[k].values[0].value);
                  }
                }
              }
              //console.log(rs.data.data);
              query = {
                "command":"create_order_pdf",
                "product":rs.data.data,
                "date": today,
                "is_sales_person": true,
                "firsName": "",
                "lastName": "",
                "phone": "",
                "currentProduct":state.currentProduct ? state.currentProduct : {}
              };
              //console.log(query);
              //console.log(data);
              result = await axios({url: state.apiRest, method: 'post', headers: headers, data: query});
              console.log(result);
              //get oder info
              headers = { 'Authorization': `Bearer ${customerToken}`};
              query = `
              {
                customer {
                  orders(currentPage:1, pageSize:10, filter: { number: { eq: "${number}"}}) {
                    total_count
                    page_info {
                      current_page
                      page_size
                      total_pages
                    }
                    
                    items {
                      id
                      number
                      status
                      comments { 
                        message
                        timestamp
                      }
                      order_date
                      total {
                        grand_total {
                          currency
                          value
                        }
                        discounts {
                          label
                          amount {
                            currency
                            value
                          }
                        }
                        subtotal {
                          currency
                          value
                        }
                        taxes {
                          title
                          rate
                          amount {
                            currency
                            value
                          }
                        }
                        total_tax {
                          currency
                          value
                        }
                        total_shipping {
                          currency
                          value
                        }
                      }
                      items {
                        id
                        product_sku
                        product_name
                        quantity_ordered
                        selected_options {
                          label
                          value
                          
                        }
                        entered_options {
                          label
                          value
                        }
                      }
                    }
                  }
                }
              }
              `;
              result = await axios({url: state.api,method: 'post', headers: headers, data: {query: query}});
              data.placeOrder.orderData = result.data.data;
              dispatch({
                type: PLACE_ORDER_OF_SALES_MANAGER,
                payload: {
                  errMessage: "",
                  errType: "",
                  placedOrder: data.placeOrder,
                  cart: {},
                  cartTotal: 0, //qty
                  cartGrandTotal: 0, //sum
                  nonceToken: "",
                  braintreeToken: "",
                  shippingAddress: {},
                  billingAddress: {},
                  shipmentMethod: {},
                  paymentMethod: {},
                },
              });
            }
          }
        }
      }
      
    }
  } catch(error) {
    console.log(error);
  }
}


export const getCategoryTree = () => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = {};
    let query = `
    {
      categoryList {
        children_count
        product_count
        name
        uid
        id
        level
        breadcrumbs {
          category_uid
          category_name
        }
        children {
          name
          uid
          id
          level
          product_count
          children_count
          breadcrumbs {
            category_uid
            category_name
          }
          children {
            name
            uid
            id
            level
            product_count
            children_count
            breadcrumbs {
              category_uid
              category_name
            }
            children {
              name
              uid
              id
              level
              product_count
              children_count
              breadcrumbs {
                category_uid
                category_name
              }
              children {
                name
                uid
                id
                level
                product_count
                children_count
                breadcrumbs {
                  category_uid
                  category_name
                }
              }
            }
          }
        }
      }
    }
    `;
    let result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          errType: ERR_TYPE_GET_CATEGORY_TREE,
        },
      });
    } else {
      const data = result.data.data;
      dispatch({
        type: GET_CATEGORY_TREE,
        payload: {
          errMessage: "",
          errType: "",
          categoryTree: data.categoryList,
        },
      });
    }
  } catch(error) {
    console.log(error);
  }
}


export const getCategoryInfo = (uid) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = {};
    function queryCategories(uid) { 
      let query = `
      {
        categories(filters: {
          category_uid: {
            eq: "${uid}"
          }
        })
        {
          page_info {
            current_page
            page_size
            total_pages
          }
          total_count
          items {
            uid
            name
            image
            product_count
            children_count
            breadcrumbs {
              category_uid
              category_name
              category_level
            }
            children {
              uid
              name
              image
              product_count
              children_count
            }
          }
        }
      }
      `;
      return query;
    }
    if (!uid) {
      let query = `{ categories(filters: {name:{match: "Products"}}) { items { uid } } }`;
      let result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});
      let data = result.data;
      if (data.hasOwnProperty('data')) {
        let _uid = data.data.categories.items[0].uid;
        let result = await axios({url: state.api, method: 'post', headers: headers, data: { query:  queryCategories(_uid) }});
        if (result.data.hasOwnProperty("errors")) {
          dispatch({
            type: SET_ERROR,
            payload: {
              errMessage: String(result.data.errors[0].message),
              errType: ERR_TYPE_GET_CATEGORY_INFO,
            },
          });
        } else {
          const data = result.data.data;
          dispatch({
            type: GET_CATEGORY_INFO,
            payload: {
              errMessage: "",
              errType: "",
              categoryInfo: data.categories.items[0],
            },
          });
        }
      }
    } else {
      let result = await axios({url: state.api, method: 'post', headers: headers, data: { query: queryCategories(uid) }});
      if (result.data.hasOwnProperty("errors")) {
        dispatch({
          type: SET_ERROR,
          payload: {
            errMessage: String(result.data.errors[0].message),
            errType: ERR_TYPE_GET_CATEGORY_INFO,
          },
        });
      } else {
        const data = result.data.data;
        dispatch({
          type: GET_CATEGORY_INFO,
          payload: {
            errMessage: "",
            errType: "",
            categoryInfo: data.categories.items[0],
          },
        });
      }
    }
  } catch(error) {
    console.log(error);
  }
}



export const getCountries = () => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    const query = `
    {
      countries {
        id
        full_name_english
        available_regions {
          code
          id
          name
        }
      }
    }
    `;
    let result = await axios({ url: state.api, method: 'post', data: { query: query }});
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          errType: ERR_TYPE_GET_COUNTRIES,
        },
      });
    } else {
      const data = result.data.data;
      dispatch({
        type: GET_COUNTRIES,
        payload: {
          errMessage: "",
          errType: "",
          countries: data.countries,
        },
      });
    }
    
  } catch (error) {
    console.log("Error", error);
  }
};

export const deleteCustomerAddress = (customerToken, addressId) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = { 'Authorization': `Bearer ${customerToken}`};
    let query = `
    mutation {
      updateCustomerAddress(id: ${addressId}, input: {
        default_shipping: false
        default_billing: false
      }) {
        city
      } 
    }
    `;
    let result = await axios({ url: state.api, method: 'post', headers: headers, data: { query: query }});
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          errType: ERR_TYPE_DELETE_CUSTOMER_ADDRESS,
        },
      });
    } else {
      query = `
      mutation {
        deleteCustomerAddress(id: ${addressId}) 
      }
      `;
      let result = await axios({ url: state.api, method: 'post', headers: headers, data: { query: query }});
      if (result.data.hasOwnProperty("errors")) {
        dispatch({
          type: SET_ERROR,
          payload: {
            errMessage: String(result.data.errors[0].message),
            errType: ERR_TYPE_DELETE_CUSTOMER_ADDRESS,
          },
        });
      } else {
        query = `
        {
          customer {
            addresses {
              id
              firstname
              lastname
              company
              street
              city
              region {
                region
                region_code
                region_id
              }
              region_id
              postcode
              country_code
              telephone
              default_billing
              default_shipping
            }
            
          }
        }
        `;
        let result = await axios({ url: state.api, method: 'post', headers: headers, data: { query: query }});
        console.log(result.data);
        if (result.data.hasOwnProperty("errors")) {
          dispatch({
            type: SET_ERROR,
            payload: {
              errMessage: String(result.data.errors[0].message),
              errType: ERR_TYPE_DELETE_CUSTOMER_ADDRESS,
            },
          });
        } else {
          const data = result.data.data;
          dispatch({
            type: DELETE_CUSTOMER_ADDRESS,
            payload: {
              errMessage: "",
              errType: "",
              addresses: data.customer.addresses,
              selectedShippingAddressId: null,
              selectedBillingAddressId: null,
              shippingAddress: {},
              billingAddress: {},
            },
          });
        }
      }
    }
  } catch (error) {
    console.log("Error", error);
  }
};



export const getAddresses = (customerToken) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = { 'Authorization': `Bearer ${customerToken}`};
    const query = `
    {
      customer {
        addresses {
          id
          firstname
          lastname
          company
          street
          city
          region {
            region
            region_code
            region_id
          }
          region_id
          postcode
          country_code
          telephone
          default_billing
          default_shipping
        }
        
      }
    }
    `;
    let result = await axios({ url: state.api, method: 'post', headers: headers, data: { query: query }});
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          errType: ERR_TYPE_GET_ADDRESSES,
          addresses: [],
          selectedShippingAddressId: null,
          selectedBillingAddressId: null,
          shippingAddress: {},
          billingAddress: {},
        },
      });
    } else {
      const data = result.data.data;
      dispatch({
        type: GET_ADDRESSES,
        payload: {
          errMessage: "",
          errType: "",
          addresses: data.customer.addresses,
          selectedShippingAddressId: null,
          selectedBillingAddressId: null,
          shippingAddress: {},
          billingAddress: {},
        },
      });
    }
    
  } catch (error) {
    console.log("Error", error);
  }
};


export const setCustomerAddressesAsDefalut = (customerToken, addressId, value, forShippingOrBilling) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = { 'Authorization': `Bearer ${customerToken}`};
    let query = ``;
    if (forShippingOrBilling === 0) {
      query = `
      mutation {
        updateCustomerAddress(id: ${addressId}, input: {
          default_shipping: ${value}
        }) {
          city
        } 
      }
      `;
    } else {
      query = `
      mutation {
        updateCustomerAddress(id: ${addressId}, input: {
          default_billing: ${value}
        }) {
          city
        } 
      }
      `;
    }
    let result = await axios({ url: state.api, method: 'post', headers: headers, data: { query: query }});
    console.log(result.data);
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          errType: ERR_TYPE_SET_CUSTOMER_ADDRESS_AS_DEFAULT,
        },
      });
    } else {
      query = `
      {
        customer {
          addresses {
            id
            firstname
            lastname
            company
            street
            city
            region {
              region
              region_code
              region_id
            }
            region_id
            postcode
            country_code
            telephone
            default_billing
            default_shipping
          }
        }
      }
      `;
      let result = await axios({ url: state.api, method: 'post', headers: headers, data: { query: query }});
      console.log(result.data);
      if (result.data.hasOwnProperty("errors")) {
        dispatch({
          type: SET_ERROR,
          payload: {
            errMessage: String(result.data.errors[0].message),
            errType: ERR_TYPE_SET_CUSTOMER_ADDRESS_AS_DEFAULT,
          },
        });
      } else {
        const data = result.data.data;
        dispatch({
          type: SET_CUSTOMER_ADDRESS_AS_DEFAULT,
          payload: {
            errMessage: "",
            errType: "",
            addresses: data.customer.addresses,
          },
        });
      }
    }
  } catch (error) {
    console.log("Error", error);
  }
};

export const selectAddressId = (addressId, forShipping) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    if (forShipping) {
      dispatch({
        type: SELECT_SHIPPING_ADDRESS_ID,
        payload: {
          errMessage: "",
          errType: "",
          selectedShippingAddressId: addressId,
        },
      });
    } else {
      dispatch({
        type: SELECT_BILLING_ADDRESS_ID,
        payload: {
          errMessage: "",
          errType: "",
          selectedBillingAddressId: addressId,
        },
      });
    }
  } catch (error) {
    console.log("Error", error);
  }
};

export const removeItemFromCart = (customerToken, cart_id, item_uid) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = { 'Authorization': `Bearer ${customerToken}`};
    let query = `
    mutation {
      removeItemFromCart(input: {
        cart_id: "${cart_id}"
        cart_item_uid: "${item_uid}"
      })
      {
        cart {
          id
          applied_coupons { code }
          prices {
            subtotal_excluding_tax { currency, value }
            subtotal_including_tax { currency, value }
            subtotal_with_discount_excluding_tax { currency, value }
            grand_total { currency, value }
            discounts {
              label
              amount {
                currency
                value
              }
            }
            applied_taxes {
              amount { currency, value }
              label
            }
          }
          items {
            uid
            quantity
            product {
              name
              description {
                html
              }
              image {
                label
                url
              }
              price_range {
                maximum_price {
                  final_price
                  {
                    currency
                    value
                  }
                }
              }
            }
            ... on SimpleCartItem {
              customizable_options {
                label
                type
                values {
                  value
                  label
                }
              }
            }
          }
        }
      }
    }
    `;
    let result = await axios({url: state.api, method: 'post', headers: headers, data: { query: query }});
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          errType: ERR_TYPE_REMOVE_ITEM_FROM_CART,
        },
      });
    } else {
      const data = result.data.data.removeItemFromCart;
      console.log(data);
      let grandTotal = 0;
      if (data.cart.hasOwnProperty('prices')) {
        if (data.cart.prices.hasOwnProperty('grand_total')) {
          grandTotal = data.cart.prices.grand_total.value;
        }
      }
      dispatch({
        type: REMOVE_ITEM_FROM_CART,
        payload: {
          errMessage: "",
          errType: "",
          cart: data.cart,
          cartGrandTotal: grandTotal,
        },
      });
    }
  } catch(error) {
    console.log(error);
  }
}

export const searchProducts = (text) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    const query = `
    {
      products (search:"${text}", currentPage:1, pageSize:20) {
        items {
          uid
          name
          sku
          short_description {
            html
          }
          image {
           url 
          }
        }
      }
    }
    `;
    let result = await axios({url: state.api,method: 'post',data: {query: query}});
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
          errType: ERR_TYPE_SEARCH_PRODUCTS,
          searchedProducts: []
        },
      });
    } else {
      const data = result.data.data;
      dispatch({
        type: SEARCH_PRODUCTS,
        payload: {
          searchedProducts: data.products.items,
          errMessage: "",
          errType: ""
        },
      });
    }
  } catch (error) {
    console.log("Error", error);
    dispatch({
      type: SET_ERROR,
      payload: {
        errMessage: String(error.message),
        errType: ERR_TYPE_SEARCH_PRODUCTS,
        searchedProducts: []
      },
    });
  }
};


export const activateNewCustomer = (email, key) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    const headers = {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
    };
    const query = {
      "command":"activate",
      "email":email,
      "key":key
    };
    let result = await axios({url: state.apiRest, method: 'post', headers: headers, data: query});
    console.log(result);
    if (result.data.hasOwnProperty("email") === false) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: JSON.stringify(result.data),
          customerActivateResponse:{},
          errType: ERR_TYPE_ACTIVATE_NEW_CUSTOMER,
        },
      });
    } else {
      const data = result.data;
      console.log(data);
      dispatch({
        type: ACTIVATE_NEW_CUSTOMER,
        payload: {
          customerActivateResponse: data,
          errMessage: "",
          errType: ""
        },
      });
    }
  } catch (error) {
    console.log("Error", error);
  }
};


export const getFilesFromStorage = (files) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    const headers = {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
    };
    const query = {
      "command":"get_files_from_storage",
      "files":files,
    };
    let result = await axios({url: state.apiRest, method: 'post', headers: headers, data: query});
    //console.log(result);
    if (Array.isArray(result.data)===false) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: JSON.stringify(result.data),
          files3D:[],
          errType: ERR_TYPE_GET_FILES_FROM_STORAGE,
        },
      });
    } else {
      const data = result.data;
      console.log(data);
      dispatch({
        type: GET_FILES_FROM_STORAGE,
        payload: {
          files3D: data,
          errMessage: "",
          errType: ""
        },
      });
    }
  } catch (error) {
    console.log("Error", error);
  }
};
