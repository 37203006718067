import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Axis, ContentContainer, Head, Content, Filler, Footer, Header, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import { CircularProgress, Divider} from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {HeaderNav, HeaderMenuButton } from './Components';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Link, useNavigate } from "react-router-dom";

import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline } from '../Components/TypographyComponents'; 

import { 
  logOutMagento, 
  getCart, 
  placeOrderOfSalesManager,
  resetAddressesOnCart,
  removeItemFromCart,
  resetState,
  getCustomerInfo,
} from "../redux/actions";
import { isTokenValid } from '../ApiHelper';
import { 
  ERR_TYPE_GET_CART,
  ERR_TYPE_SET_SHIPPING_ADDRESS, 
  ERR_TYPE_SET_BILLING_ADDRESS, 
  ERR_TYPE_SET_PAYMENT_METHOD, 
  ERR_TYPE_SET_SHIPPING_METHOD, 
  ERR_TYPE_GET_BRAINTREE_TOKEN, 
  ERR_TYPE_SET_NONCE_TOKEN, 
  ERR_TYPE_PLACE_ORDER,
  ERR_TYPE_SET_SALES_MANAGER_COUPON_TO_CART,
  ERR_TYPE_PLACE_ORDER_OF_SALES_MANAGER,
} from "../redux/types";
//import BraintreeForm from '../Braintree/BraintreeForm';
import { Braintree, BraintreeHostedField } from "../Braintree/index";
import OrderSummary from '../Components/OrderSummary';

var _ = require('lodash');
var he = require('he');

function CartView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav>
        </MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer>
          <Column width="100%" center='true'>
            <Header6>Footer</Header6>
          </Column>
        </Footer>
      </ContentContainer>
    </ThemeProvider>
  );

}


function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const navigate = useNavigate();

  //const [prodQty, setProdQty] = React.useState([]);

  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const customerEmail = stateObj.customerEmail;
  const errMessage = stateObj.errMessage;
  const errType = stateObj.errType;
  const cart = stateObj.cart;
  const cart_id = stateObj.cart_id;
  const cartGrandTotal = stateObj.cartGrandTotal;
  const placedOrder = stateObj.placedOrder;
  const customerInfo = stateObj.customerInfo;
  const GROUP_SALES_PERSON = stateObj.customer_group_sales_person;
  let isSalesManager = stateObj.isSalesManager;
  const SALES_MANAGER = 4;

  if (_.isEmpty(customerInfo) === false) {
    if (customerInfo.group_id === SALES_MANAGER) {
      isSalesManager = true;
    }
  }

  let orderInfo = {...placedOrder};

  let alert = <Row></Row>;
  if (errMessage !== "" && (
    errType === ERR_TYPE_PLACE_ORDER_OF_SALES_MANAGER || 
    errType === ERR_TYPE_GET_CART
    ) ) {
    alert = <Row><Alert severity='error' sx={{marginTop:"20px", marginBottom:"10px"}}><AlertTitle>{errType}</AlertTitle>{errMessage}</Alert></Row>;
  }

  const continueShoppingHandler = () => {
    navigate("/");
  }

  let success = <Column></Column>;  
  if (_.isEmpty(placedOrder) === false) {
    console.log(placedOrder);
    success =
      <Column top={60} bottom={60}>
        <Header5>Thank you for your purchase!</Header5>
        <Row top={30}>
          <Body1>Your order number is: {placedOrder.order.order_number}.<br/>We'll email you an order confirmation with details and tracking info.</Body1>
        </Row>
        <Row top={40}>
          <Button onClick={continueShoppingHandler} variant='contained' sx={{color:'white'}}>Continue Shopping</Button>
        </Row>
      </Column>;
  }

  //console.log(customerInfo);

  useEffect(()=>{

    dispatch(resetState({placedOrder:{}}));
    
    if (customerToken !== "") {
      isTokenValid(customerToken, (result)=>{
        if (result) {
          dispatch(getCart(customerToken));
          if (_.isEmpty(customerInfo)) {
            dispatch(getCustomerInfo(customerToken));
          }
        } else {
          dispatch(logOutMagento());
        }
      });
    }

    if (_.isEmpty(placedOrder) === false) {
      
    }

    /*try {
      let v = [];
      for (let el of cart.items) {
        v.push(el.quantity);
      }
      setProdQty(v);
    } catch { }*/

    /*return (()=>{
      resetState({placedOrder:{}});
    });*/
  },[cart_id, customerToken, cartGrandTotal, isSalesManager]);

  //console.log(cart);
  

  /*const prodQtyHandler = (e) => {
    let i = parseInt(e.currentTarget.id.substring(3));
    let temp = [...prodQty];
    temp[i] = e.currentTarget.value;
    setProdQty(temp);
  }*/

  let leftPanelWidth = lt.bunch12;
  let rightPanelWidth = lt.bunch4;
  if (lt.max === 16) {
    leftPanelWidth = lt.bunch12;
  } else if (lt.max === 12) {
    leftPanelWidth = lt.bunch9;
    rightPanelWidth = lt.bunch3;
  } else if (lt.max === 8) {
    leftPanelWidth = lt.bunch5;
    rightPanelWidth = lt.bunch3;
  } else if (lt.max === 4) {
    leftPanelWidth = lt.bunch4;
    rightPanelWidth = lt.bunch4;
  }

  let priceColWidth = 80;
  let qtyColWidth = 80;
  let subtotalColWidth = 80;
  if (lt.max === 16) {
    priceColWidth = 80;
    qtyColWidth = 80;
    subtotalColWidth = 80;
  } else if (lt.max === 12) {
    priceColWidth = 80;
    qtyColWidth = 80;
    subtotalColWidth = 80;
  } else if (lt.max === 8) {
    priceColWidth = 60;
    qtyColWidth = 60;
    subtotalColWidth = 60;
  }

  let cartItems = [];

  if (cart.hasOwnProperty('items')) {
    cartItems = cart.items;
  }

  let discounts = 0;
  try {
    for (let d of cart.prices.discounts) {
      discounts += d.amount.value;
    }
  } catch {}

  const placeOrderOfSalesManagerHandler = () => {
    //console.log("place order");
    //dispatch(placeOrderOfSalesManager(customerToken, cart_id));
    navigate(`/cart_result/?cart_id=${cart_id}`);
  }

  const checkoutHandler = () => {
    dispatch(resetAddressesOnCart());
    navigate("/checkout");
  }
  
  const deleteProductHandler = (event) => {
    const item_id = event.currentTarget.dataset.id;
    //console.log(item_id);
    dispatch(removeItemFromCart(customerToken, cart_id, item_id));
  }

  const logInHandler = () => {

  }

  let summary = <div></div>;
  if (isSalesManager) {
    summary = <Column left={lt.max === 4 ? 0: 20} right={lt.max === 4 ? 0: 20} top={10} >
      <Button variant='contained' sx={{color:'white'}} onClick={placeOrderOfSalesManagerHandler}>Place order</Button>
    </Column>;
  } else {
    if (cart && cart.hasOwnProperty('prices') && cart.prices.grand_total.value > 0) {
      summary = <Column>
        <OrderSummary cart={cart} width={rightPanelWidth} left={lt.max === 4 ? 10: 20} right={lt.max === 4 ? 10: 0}></OrderSummary>
        <Row main={Axis.main.end} top={20} left={lt.max === 4 ? 10: 20} right={lt.max === 4 ? 10: 0}><Button variant='contained' size='large' sx={{color:"#fff", minWidth: "100%"}} onClick={checkoutHandler}>Checkout</Button></Row>
      </Column>;
    } else {
      summary = <Column>
        <OrderSummary cart={cart} width={rightPanelWidth} left={lt.max === 4 ? 10: 20} right={lt.max === 4 ? 10: 0}></OrderSummary>
        <Row main={Axis.main.end} top={20} left={lt.max === 4 ? 10: 20} right={lt.max === 4 ? 10: 0}><Button variant='contained' size='large' sx={{color:"#fff", minWidth: "100%"}} onClick={checkoutHandler}>Next</Button></Row>
      </Column>;
    }
  }

  let leftPanel = 
  <Column width={leftPanelWidth} >
    <Column left={lt.max === 4 ? 10 : 20} right={lt.max === 4 ? 10 : 20}>
      <Header3>Shopping Bag</Header3>
      <Row width="100%" top={30} bottom={0} main={Axis.main.between}>
        <Row>
          <Column>
            <Row><Caption>item</Caption></Row>
          </Column>
        </Row>
      </Row>
      <Divider sx={{width:"100%", marginTop:"10px", marginBottom:"10px"}}></Divider>
      <Column width="100%" main={Axis.main.between}>
        {cartItems.map((v,i)=>{
          return (
            <React.Fragment key={"cartitem"+i} >
            <Row width="100%" main={Axis.main.between}>
              <div style={{display: 'flex', flexDirection: lt.max === 4 ? 'column' : 'row'}}>
                <Column width={120} right={10} top={10} >
                  <img src={v.product.image.url} alt={v.product.image.label}></img>
                </Column>
                <Column left={0} top={lt.max === 4 ? 20 : 0} >
                  <Row><Header6>{v.product.name}</Header6></Row>
                  <Row top={5} bottom={2}><Subtitle2>Pieces: {v.quantity}</Subtitle2></Row>
                  <Row top={10}><Body2 dangerouslySetInnerHTML={{__html: v.product.description.html}}></Body2></Row>
                  {v.customizable_options.map((option,i)=>{
                    let text = "";
                    let label = option.label;
                    if (customerInfo && customerInfo.hasOwnProperty('group_id')) {
                      if (label === "Sales") {
                        if (customerInfo.group_id === GROUP_SALES_PERSON) {
                          label = "Sales Person";    
                        } else {
                          return null;
                        }
                      }
                    } 
                    for (let v of option.values){
                      if (option.type === "drop_down") {
                        text += v.label+',';
                      } else if (option.type === "area" || option.type === "field") {
                        text += v.value+',';
                      }
                    }
                    if (text.length > 1) {
                      text = text.substring(0, text.length-1);
                    }

                    let title = text;
                    
                    return(
                      <Column key={"option"+i} top={10}>
                        <Row top={5}><Subtitle2>{label}</Subtitle2></Row>
                        <Row top={5} left={15}><Body2>{he.decode(title)}</Body2></Row>
                      </Column>
                    );
                  })}
                </Column>
              </div>
            </Row>
            <Row main={Axis.main.end} top={10}>
              <IconButton aria-label="delete" onClick={deleteProductHandler} data-id={v.uid}>
                <DeleteIcon />
              </IconButton>
            </Row>
            <Divider sx={{width:"100%", marginTop:"10px", marginBottom:"10px"}}></Divider>
            </React.Fragment>
          );
        })}
        
      </Column>
    </Column>
  </Column>

  let content = 
  <div style={{display: customerToken ? 'block': 'none'}} >
    <div style={{display: _.isEmpty(placedOrder) ? 'block': 'none'}}>
      <Column width={lt.innerWidth} top={40} bottom={80} cross={Axis.cross.center} >
        { _.isEmpty(cart) && 
          <Row left={20} right={20} self={Axis.self.start} center='true'>
            <Header3>Shopping Bag</Header3><Row left={20}><CircularProgress size={30}></CircularProgress></Row>
          </Row>
        }
        <div style={{display: _.isEmpty(cart) ? 'none': 'block'}}>
          { cart.hasOwnProperty("items") && cart.items.length === 0 &&
            <Column top={60}>
              <Row left={20} right={20} self={Axis.self.start}>
                <Header4>Shopping Bag is empty</Header4>
              </Row>
            </Column>
          }
          <div style={{display: (cart.hasOwnProperty("items") && cart.items.length === 0) ? "none": "block"}}>
            <div style={{display: lt.max !== 4 ? 'block': 'none'}}>
              <Row width="100%" main={Axis.main.between} >
                {leftPanel}
                <Column width={rightPanelWidth} cross={Axis.cross.end} >
                  {summary}
                </Column>
              </Row>
            </div>
            <div style={{display: lt.max === 4 ? 'block': 'none'}}>
              <Column width="100%">
                {leftPanel}
                <Column width={rightPanelWidth} top={10} cross={lt.max === 4 ? Axis.cross.center : Axis.cross.end}>
                  {summary}
                </Column>
              </Column>
            </div>
          </div>
        </div>
      </Column>
      {alert}
    </div>
    <div style={{display: _.isEmpty(placedOrder) ? 'none': 'block'}}>
      {success}
    </div>
  </div>;

  return(
    <Column width="100%" center>
      <div style={{display: lt.max >= 8 && lt.max <= 16 ? 'block': 'none'}}>
        <div style={{display: !customerToken ? 'block': 'none'}}>
          <Column width={lt.innerWidth} sx={{minHeight:300}} center>
            <Row><Header5>Log in with your email address or create a new account.</Header5></Row>
            <Row top={30} left={20} right={20}>
              <Link to={"/login"} style={{textDecoration: "none"}}>
                <Button variant='contained' sx={{color:'white'}}>Continue</Button>
              </Link>
            </Row>
          </Column>
        </div>
        {content}
      </div>
      <div style={{display: lt.max === 4 ? 'block': 'none'}}>
        <div style={{display: !customerToken ? 'block': 'none'}}>
          <Column width={lt.innerWidth} sx={{minHeight:300}} center>
            <Row><Header5>Log in with your email address or create a new account.</Header5></Row>
            <Row top={30} left={20} right={20}>
              <Link to={"/login"} style={{textDecoration: "none"}}>
                <Button variant='contained' sx={{color:'white'}}>Continue</Button>
              </Link>
            </Row>
          </Column>
        </div>
        {content}
      </div>
    </Column>
  );
}


export default CartView16;